import React from 'react'
import { inject, observer } from 'mobx-react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { SvgBuilder } from './components/SvgBuilder'
import { App } from './components/App'
import { Slides } from './components/Slides'
import Cover from './components/Cover'
import { Credits } from './components/Credits'

function RedirectToHomepage() {
  return <Redirect to={`${process.env.SUBDIRECTORY}/`} />
}

@inject('state')
@observer
export class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path={`${process.env.SUBDIRECTORY}/`} component={Cover} />
        <Route exact path={`${process.env.SUBDIRECTORY}/intro/:id?`} component={Slides} />
        <Route exact path={`${process.env.SUBDIRECTORY}/explorer`} component={App} />
        <Route exact path={`${process.env.SUBDIRECTORY}/credits`} component={Credits} />
        {process.env.GENERATE_SOURCEMAP === 'true' && (
          <Route exact path={`${process.env.SUBDIRECTORY}/svg-builder`} component={SvgBuilder} />
        )}
        <Route component={RedirectToHomepage} />
      </Switch>
    )
  }
}
