import React from 'react'
import { inject, observer } from 'mobx-react'
import { Layer, Path } from 'react-konva'
import { mapRange } from 'canvas-sketch-util/math'
import { MAP_DATA_APPEAR_FROM, NO_DATA_STRING } from '../constants'
import { computeTrianglePath, computeSquarePath } from '../lib/path-utils'

@inject('state')
@observer
export class MapSelected extends React.Component {
  render() {
    const { map, filters } = this.props.state

    if (!filters.isSelected) return null

    const feature = map.geojson.features.find(f => f.properties.COUNTY === filters.search)

    // TODO remove duplication from this component

    return (
      <Layer listening={false}>
        <Path
          data={
            map.interpolators[feature.properties.COUNTY][
              Math.round(
                map.easedProgress * (map.interpolators[feature.properties.COUNTY].length - 1)
              )
            ]
          }
          x={
            map.easedProgress *
            (feature.geometry.scaledGridDisposition[0] - feature.geometry.projectedCentroid[0])
          }
          y={
            map.easedProgress *
            (feature.geometry.scaledGridDisposition[1] - feature.geometry.projectedCentroid[1])
          }
          fill={'white'}
          opacity={
            filters.countiesFilteredHashMap[feature.properties.COUNTY] !== NO_DATA_STRING
              ? 0.15
              : 0.04
          }
        />
        <Path
          data={
            map.interpolators[feature.properties.COUNTY][
              Math.round(
                map.easedProgress * (map.interpolators[feature.properties.COUNTY].length - 1)
              )
            ]
          }
          x={
            map.easedProgress *
            (feature.geometry.scaledGridDisposition[0] - feature.geometry.projectedCentroid[0])
          }
          y={
            map.easedProgress *
            (feature.geometry.scaledGridDisposition[1] - feature.geometry.projectedCentroid[1])
          }
          fill={'transparent'}
          stroke={'white'}
          strokeWidth={map.dimension < 600 ? 2 : 3}
        />

        {map.easedProgress > MAP_DATA_APPEAR_FROM && (
          <Path
            x={
              feature.geometry.projectedCentroid[0] +
              map.easedProgress *
                (feature.geometry.scaledGridDisposition[0] - feature.geometry.projectedCentroid[0])
            }
            y={
              feature.geometry.projectedCentroid[1] +
              map.easedProgress *
                (feature.geometry.scaledGridDisposition[1] - feature.geometry.projectedCentroid[1])
            }
            data={
              filters.isRatio
                ? computeTrianglePath({
                  maxValue: map.cellSize,
                  value:
                      mapRange(map.easedProgress, MAP_DATA_APPEAR_FROM, 1, 0, 1, true) *
                      map.cellScaleRatio(
                        filters.countiesFilteredRatioHashMap[feature.properties.COUNTY]
                      ),
                  isPositive:
                      filters.countiesFilteredRatioHashMap[feature.properties.COUNTY] >= 1,
                })
                : computeSquarePath({
                  maxValue: map.cellSize,
                  value:
                      mapRange(map.easedProgress, MAP_DATA_APPEAR_FROM, 1, 0, 1, true) *
                      map.cellScale(filters.countiesFilteredHashMap[feature.properties.COUNTY]),
                })
            }
            scaleX={0.9}
            scaleY={0.9}
            fill={
              filters.isRatio
                ? map.colorScaleRatio(
                  filters.countiesFilteredRatioHashMap[feature.properties.COUNTY]
                )
                : map.colorScale(filters.countiesFilteredHashMap[feature.properties.COUNTY])
            }
          />
        )}
      </Layer>
    )
  }
}
