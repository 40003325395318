import Stats from 'stats.js'

export class RafManager {
  listeners = []
  time = 0
  lastTime = performance.now()
  stats

  constructor() {
    // init stats.js
    this.stats = new Stats()
    this.stats.showPanel(0)

    if (window.DEBUG) {
      document.body.appendChild(this.stats.dom)
    }

    // start the main raf and don't close it ever
    requestAnimationFrame(this.update)
  }

  update = () => {
    this.stats.begin()

    // reliable time calculations
    const now = performance.now()
    const dt = (now - this.lastTime) / 1000 // convert it in seconds
    this.time += dt
    this.lastTime = now

    // call all the listeners
    this.listeners.forEach(listener => listener(dt, this.time))

    this.stats.end()
    requestAnimationFrame(this.update)
  }

  registerAnimationFrame(fn) {
    this.listeners.push(fn)
  }

  unregisterAnimationFrame(fn) {
    const index = this.listeners.indexOf(fn)

    // return silently if the function can't be found
    if (index === -1) {
      return
    }

    this.listeners.splice(index, 1)
  }
}
