import React from 'react'
import { inject, observer } from 'mobx-react'
import { SLIDES_TRANSITION_TIME } from '../../constants'

@inject('state')
@observer
export class SlideType5 extends React.Component {
  componentDidMount() {
    const { mountedFunction } = this.props.slide
    const { ui } = this.props.state

    setTimeout(() => ui.setMounted(true), SLIDES_TRANSITION_TIME)
    if (mountedFunction) mountedFunction()
  }

  render() {
    const {
      slide,
      slide: { mountedTextStyle, unmountedTextStyle },
      className = '',
    } = this.props
    const { ui } = this.props.state

    return (
      <div className={`${className} flex flex-column items-center`}>
        <div
          className={`${ui.isMobile ? 'f3' : 'f1'} lh-sarah`}
          style={{
            fontSize: ui.isMobile ? '7vw' : '',
            ...(ui.mounted ? mountedTextStyle : unmountedTextStyle),
          }}
        >
          {slide.content}
        </div>
      </div>
    )
  }
}
