import React from 'react'
import { inject, observer } from 'mobx-react'
import { CHAPTERS, CHAPTER_POPULATION_ETHNICITY, CHAPTER_POPULATION_AGE } from '../constants'
import { formatName } from '../lib/string-utils'

@inject('state')
@observer
export class Chapters extends React.Component {
  render() {
    const { className = '' } = this.props
    const { filters, ui } = this.props.state
    return (
      <div className={`${className} nowrap overflow-x-auto`}>
        {CHAPTERS.map(chapter => {
          // 😖
          if (ui.isMobile && chapter === CHAPTER_POPULATION_ETHNICITY) {
            return null
          }

          return (
            <div
              key={chapter}
              className={`
              dib user-select-none
              transition--fast
              ${chapter === CHAPTERS[CHAPTERS.length - 1] ? '' : 'mr4'}
              ${ui.isMobile ? 'f4' : 'f4'}
              ${filters.activeChapter === chapter ? '' : 'pointer o-40 hover-o-60'}
            `}
              onClick={() => filters.setActiveChapter(chapter)}
            >
              {/* 😖 */}
              {formatName(
                ui.isMobile && chapter === CHAPTER_POPULATION_AGE ? 'population' : chapter
              )}
            </div>
          )
        })}
      </div>
    )
  }
}
