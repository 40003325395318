import { types as t, applySnapshot, getRoot } from 'mobx-state-tree'
import { range, maxBy } from 'lodash'
import { geoMercator, geoPath } from 'd3-geo'
import { line } from 'd3-shape'
import { interpolatePath } from 'd3-interpolate-path'
import { scaleLinear } from 'd3-scale'
import eases from 'eases'
import { interpolateRgb } from 'd3-interpolate'
import { rgb } from 'd3-color'
import geojson from '../data/texas-counties.json'
import { CHAPTER_TRAFFIC, PALETTE, MAP_ANIMATION_DURATION } from '../constants.js'

const INIT_ZOOM = 1

export const Map = t
  .model('Map', {
    animationStart: t.maybe(t.number),
    animationProgress: 0,
    isAnimationReverse: true,
    geojsonRaw: t.frozen(geojson),
    width: 0,
    height: 0,
  })
  .views(self => ({
    get isPlaying() {
      return self.animationStart !== undefined
    },
    get dimension() {
      return Math.min(self.width, self.height)
    },
    get cols() {
      return (
        maxBy(self.geojsonRaw.features, 'geometry.gridDisposition[0]').geometry.gridDisposition[0] +
        1
      )
    },
    get rows() {
      return (
        maxBy(self.geojsonRaw.features, 'geometry.gridDisposition[1]').geometry.gridDisposition[1] +
        1
      )
    },
    // the size of a column or row
    get cellSize() {
      const colSize = self.dimension / self.cols
      const rowSize = self.dimension / self.rows

      return Math.min(colSize, rowSize)
    },
    // d3 geo math
    get projectionPath() {
      const projection = geoMercator()
      const path = geoPath()

      const boundsGeo = path.bounds(self.geojsonRaw)
      const dxGeo = boundsGeo[1][0] - boundsGeo[0][0]
      const dyGeo = boundsGeo[1][1] - boundsGeo[0][1]
      const center = [boundsGeo[0][0] + dxGeo / 2, boundsGeo[0][1] + dyGeo / 2]

      projection
        .center(center)
        .scale(INIT_ZOOM)
        // BUG! El Paso bugs out so we put + 1
        .translate([self.dimension / 2 + 1, self.dimension / 2])

      path.projection(projection)

      // Calculate optimal zoom
      const bounds = path.bounds(self.geojsonRaw)
      const dx = bounds[1][0] - bounds[0][0]
      const dy = bounds[1][1] - bounds[0][1]
      const padding = 0
      const scale = Math.max(
        1,
        1 / Math.max(dx / (self.dimension - 2 * padding), dy / (self.dimension - 2 * padding))
      )
      projection.scale(scale * INIT_ZOOM)

      return { projection, path }
    },
    get projection() {
      return self.projectionPath.projection
    },
    get path() {
      return self.projectionPath.path
    },
    // the geojson but with more stuff in it
    get geojson() {
      // append the projected centroid
      self.geojsonRaw.features.forEach(feature => {
        const projectedCentroid = self.projection(feature.geometry.centroid)
        feature.geometry.projectedCentroid = projectedCentroid
      })

      // append the scaledGridDisposition
      self.geojsonRaw.features.forEach(feature => {
        feature.geometry.scaledGridDisposition = [
          self.dimension / 2 -
            (self.cols / 2) * self.cellSize +
            feature.geometry.gridDisposition[0] * self.cellSize +
            self.cellSize / 2,
          self.dimension / 2 -
            (self.rows / 2) * self.cellSize +
            feature.geometry.gridDisposition[1] * self.cellSize +
            self.cellSize / 2,
        ]
      })

      return self.geojsonRaw
    },
    get interpolators() {
      return self.geojson.features.reduce((acc, feature, i) => {
        const squarePath = line()
          .x(d => (d[0] - 0.5) * self.cellSize + feature.geometry.projectedCentroid[0])
          .y(d => (d[1] - 0.5) * self.cellSize + feature.geometry.projectedCentroid[1])

        const interpolator = interpolatePath(
          self.path(feature),
          `${squarePath(feature.geometry.squareCoordinates)}Z`
        )

        // 16 means 60fps, 32 is 30fps
        // 30fps is fine since this does not touch translation
        const frames = MAP_ANIMATION_DURATION / 32
        acc[feature.properties.COUNTY] = range(0, 1, 1 / frames)
          .concat(1)
          .map(progress => interpolator(progress))

        return acc
      }, {})
    },
    get cellScale() {
      const { filters } = getRoot(self)

      // for the traffic data the scale starts from 20
      const minValue = filters.activeChapter === CHAPTER_TRAFFIC ? 20 : 0

      // for the rates in the health the max value is 100
      // const maxValue =
      //   filters.activeChapter === CHAPTER_HEALTH && filters.category !== CATEGORY_PREMATURE_DEATHS
      //     ? 100
      //     : filters.maxValueCategory

      return scaleLinear()
        .domain([minValue, filters.maxValueCategory])
        .range([0, self.cellSize])
    },
    get cellScaleRatio() {
      const { filters } = getRoot(self)

      return scaleLinear()
        .domain([0, 1, filters.maxRatio])
        .range([self.cellSize, 0, self.cellSize])
    },
    get colorScale() {
      return self.cellScale
        .copy()
        .interpolate(interpolateRgb)
        .range([rgb(PALETTE.ACCENT), rgb('white')])
    },
    get colorScaleRatio() {
      return self.cellScaleRatio
        .copy()
        .interpolate(interpolateRgb)
        .range([
          rgb(PALETTE.ACCENT),
          rgb('#fdbeba'), // interpolateRgb(rgb(PALETTE.ACCENT), rgb('white'))(0.5),
          rgb('white'),
        ])
    },
    get ease() {
      return self.isAnimationReverse ? eases.quartIn : eases.quartOut
    },
    get easedProgress() {
      return self.ease(self.animationProgress)
    },
  }))
  .actions(self => ({
    reset() {
      applySnapshot(self, {})
    },
    setAnimationStart(animationStart) {
      self.animationStart = animationStart
    },
    resetAnimationStart() {
      self.animationStart = undefined
    },
    setAnimationProgress(animationProgress) {
      self.animationProgress = animationProgress
    },
    resetAnimationProgress() {
      self.animationProgress = 0
    },
    setIsAnimationReverse(isAnimationReverse) {
      self.isAnimationReverse = isAnimationReverse
    },
    setWidth(width) {
      self.width = width
    },
    setHeight(height) {
      self.height = height
    },
  }))
  .actions(self => ({
    play() {
      self.setIsAnimationReverse(self.animationProgress === 1)

      self.setAnimationStart(performance.now())
      // start loop
      window.rafManager.registerAnimationFrame(self.update)
    },
    stop() {
      if (self.isAnimationReverse) {
        self.setAnimationProgress(0)
      } else {
        self.setAnimationProgress(1)
      }

      self.resetAnimationStart()
      // end loop
      window.rafManager.unregisterAnimationFrame(self.update)
    },
    update() {
      const animationProgress = (performance.now() - self.animationStart) / MAP_ANIMATION_DURATION

      // stop animating if we finish
      if (animationProgress >= 1) {
        self.stop()
        return
      }

      self.setAnimationProgress(self.isAnimationReverse ? 1 - animationProgress : animationProgress)
    },
  }))
