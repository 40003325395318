import React from 'react'
import { inject, observer } from 'mobx-react'
import { SLIDES_PROGRESS_BAR_HEIGTH } from './../constants'

@inject('state')
@observer
export class SlidesBar extends React.Component {
  render() {
    const { className = '', index, slidesNumber } = this.props
    const { ui } = this.props.state

    return (
      <div className={`bg-blue w-100 ${className}`}>
        <div
          className="bg-accent"
          style={{
            width: (ui.windowWidth / slidesNumber) * (index + 1),
            height: SLIDES_PROGRESS_BAR_HEIGTH,
            transition: 'width 500ms ease',
          }}
        />
      </div>
    )
  }
}
