import React from 'react'
import { inject, observer } from 'mobx-react'
import { SlideType1 } from './slides/SlideType1'
import { SlideType2 } from './slides/SlideType2'
import { SlideType3 } from './slides/SlideType3'
import { SlideType4 } from './slides/SlideType4'
import { SlideType5 } from './slides/SlideType5'

@inject('state')
@observer
export class Slide extends React.Component {
  getSlideComponent = type => {
    switch (type) {
      case 'text-center':
        return SlideType1
      case 'text-and-image':
        return SlideType2
      case 'text-and-image-des-mob':
        return SlideType3
      case 'numbers':
        return SlideType4
      case 'text-top':
        return SlideType5
      default:
        throw new Error(`Unknown slide type ${type}`)
    }
  }

  render() {
    const { className = '', slide } = this.props

    const SlideComponent = this.getSlideComponent(slide.type)

    return <SlideComponent slide={slide} className={`${className}`} />
  }
}
