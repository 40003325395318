import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as MobxProvider } from 'mobx-react'
import { Router } from 'react-router-dom'
import { RouterModel, syncHistoryWithStore } from 'mst-react-router'
import { createBrowserHistory } from 'history'
import { Routes } from './Routes'
import { State } from './state'
import { RafManager } from './lib/RafManager'
import 'modern-normalize'
import '@accurat/tachyons-lite'
import 'tachyons-extra'
import './reset.css'
import './style.css'

const state = State.create({
  routing: RouterModel.create(),
})

window.DEBUG = window.location.search.includes('debug')
window.rafManager = new RafManager()

if (process.env.GENERATE_SOURCEMAP === 'true') {
  window.state = state
}

// Wire up the router and the state
const history = syncHistoryWithStore(createBrowserHistory(), state.routing)

ReactDOM.render(
  <MobxProvider state={state}>
    <Router history={history} basename={process.env.SUBDIRECTORY}>
      <Routes />
    </Router>
  </MobxProvider>,
  document.getElementById('root')
)
