import React from 'react'
import { inject, observer } from 'mobx-react'
import { Layer, Rect } from 'react-konva'

@inject('state')
@observer
export class MapBackground extends React.Component {
  render() {
    const { map, filters } = this.props.state

    return (
      <Layer>
        {/* click outside of the map */}
        <Rect
          x={0}
          y={0}
          width={map.dimension}
          height={map.dimension}
          onClick={filters.resetSearch}
          preventDefault={false}
        />
      </Layer>
    )
  }
}
