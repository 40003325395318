import React from 'react'
import { inject, observer } from 'mobx-react'
import { Layer, Path } from 'react-konva'
import { mapRange } from 'canvas-sketch-util/math'
import { reaction } from 'mobx'
import { MAP_DATA_APPEAR_FROM } from '../constants'
import { computeTrianglePath, computeSquarePath } from '../lib/path-utils'

@inject('state')
@observer
export class MapData extends React.Component {
  layer = React.createRef()

  get canvas() {
    if (!this.layer.current) {
      return null
    }

    return this.layer.current.getCanvas()._canvas
  }

  componentDidMount() {
    const { filters } = this.props.state

    this.setOpacity()
    this.disposeReaction = reaction(() => filters.isSelected, this.setOpacity)
  }

  componentWillUnmount() {
    this.disposeReaction()
  }

  setOpacity = () => {
    const { filters } = this.props.state

    this.canvas.style.opacity = filters.isSelected ? 0.3 : 1
  }

  render() {
    const { map, filters } = this.props.state

    const easedValue = mapRange(map.easedProgress, MAP_DATA_APPEAR_FROM, 1, 0, 1, true)

    return (
      <Layer listening={false} ref={this.layer}>
        {map.easedProgress >= MAP_DATA_APPEAR_FROM &&
          map.geojson.features.map(feature => (
            <Path
              key={feature.properties.COUNTY}
              x={
                feature.geometry.projectedCentroid[0] +
                map.easedProgress *
                  (feature.geometry.scaledGridDisposition[0] -
                    feature.geometry.projectedCentroid[0])
              }
              y={
                feature.geometry.projectedCentroid[1] +
                map.easedProgress *
                  (feature.geometry.scaledGridDisposition[1] -
                    feature.geometry.projectedCentroid[1])
              }
              data={
                filters.isRatio
                  ? computeTrianglePath({
                    maxValue: map.cellSize,
                    value:
                        easedValue *
                        map.cellScaleRatio(
                          filters.countiesFilteredRatioHashMap[feature.properties.COUNTY]
                        ),
                    isPositive:
                        filters.countiesFilteredRatioHashMap[feature.properties.COUNTY] >= 1,
                  })
                  : computeSquarePath({
                    maxValue: map.cellSize,
                    value:
                        easedValue *
                        map.cellScale(filters.countiesFilteredHashMap[feature.properties.COUNTY]),
                  })
              }
              scaleX={0.9}
              scaleY={0.9}
              fill={
                filters.isRatio
                  ? map.colorScaleRatio(
                    filters.countiesFilteredRatioHashMap[feature.properties.COUNTY]
                  )
                  : map.colorScale(filters.countiesFilteredHashMap[feature.properties.COUNTY])
              }
            />
          ))}
      </Layer>
    )
  }
}
