import React from 'react'
import { inject, observer } from 'mobx-react'
import { Swipeable } from 'react-swipeable'
import { attachListenersToKeys } from '@accurat/event-utils'
import { debounce } from 'lodash'

function attachUpDownToMouseWheel({ up, down }) {
  return debounce(
    event => {
      if (event.deltaY > 0) {
        down()
      } else {
        up()
      }
    },
    50,
    { leading: true, trailing: false }
  )
}

@inject('state')
@observer
export class Swiper extends React.Component {
  componentDidMount() {
    this.keysListener = attachListenersToKeys({
      ArrowDown: this.slideNext,
      ArrowUp: this.slidePrev,
    })

    this.mouseWheelListener = attachUpDownToMouseWheel({
      up: this.slidePrev,
      down: this.slideNext,
    })

    document.addEventListener('keydown', this.keysListener)
    document.addEventListener('mousewheel', this.mouseWheelListener)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keysListener)
    document.removeEventListener('mousewheel', this.mouseWheelListener)
  }

  slidePrev = () => {
    const { index } = this.props
    const { transitionSlide } = this.props.state.ui

    if (index !== -1) transitionSlide(index)
  }

  slideNext = () => {
    const { index } = this.props
    const { transitionSlide } = this.props.state.ui

    transitionSlide(index + 2)
  }

  render() {
    return (
      <Swipeable
        {...this.props}
        onSwipedDown={this.slidePrev}
        onSwipedUp={this.slideNext}
        trackMouse
        preventDefaultTouchmoveEvent
      />
    )
  }
}
