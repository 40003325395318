import React from 'react'
import { inject, observer } from 'mobx-react'
import { formatName } from '../lib/string-utils'
import { CHAPTER_POPULATION_AGE } from '../constants'

@inject('state')
@observer
export class Title extends React.Component {
  render() {
    const { className = '' } = this.props
    const { filters, ui } = this.props.state

    // 😖
    const chapter =
      ui.isMobile && filters.activeChapter === CHAPTER_POPULATION_AGE
        ? 'population'
        : filters.activeChapter

    return (
      <div className={`${className} letter-spacing-small ${ui.isMobile ? 'f3' : 'f2'}`}>
        {formatName(chapter)} in {filters.year}
      </div>
    )
  }
}
