export function saveSvg(name, svgString) {
  const blob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })

  // force download
  const link = document.createElement('a')
  link.download = name
  link.href = window.URL.createObjectURL(blob)
  link.onclick = setTimeout(() => {
    window.URL.revokeObjectURL(blob)
    link.removeAttribute('href')
  }, 0)

  link.click()
}
