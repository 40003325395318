import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { scaleLinear } from 'd3-scale'
import { withParentSize } from '@vx/responsive'
import { reaction } from 'mobx'
import { PALETTE, TIMELINE_INTERVAL, TIMELINE_HEIGTH } from '../constants'

function Ticks({ xScale, handleHeight, center, allTicksNumber }) {
  const ticks = xScale.ticks(Math.min(5, allTicksNumber))

  return ticks.map(tick => (
    <g key={tick}>
      <circle cx={xScale(tick)} cy={center} r={3} fill="white" stroke="none" />
      <text
        className="pointer-events-none user-select-none f6 semi-b"
        textAnchor="middle"
        dominantBaseline="middle"
        x={xScale(tick)}
        y={center + handleHeight * 1.4}
        fill="white"
        fillOpacity={0.4}
      >
        {tick}
      </text>
    </g>
  ))
}

class Handle extends Component {
  startDragging = () => {
    document.body.classList.add('grabbing')
    document.body.addEventListener('mousemove', this.props.onChange)

    document.body.addEventListener('mouseup', this.stopDragging, { once: true })
    document.body.addEventListener('mouseleave', this.stopDragging, { once: true })
  }

  stopDragging = () => {
    document.body.classList.remove('grabbing')
    document.body.removeEventListener('mousemove', this.props.onChange)
  }

  render() {
    const { xScale, center, handleHeight, value, className = '', ...props } = this.props

    const x = xScale(value)

    return (
      <>
        <circle
          cx={x}
          cy={center}
          r={handleHeight / 2}
          stroke="none"
          className={`cursor-grab ${className}`}
          onPointerDown={this.startDragging}
          {...props}
        />
        <text
          className="pointer-events-none user-select-none f6 semi-b"
          textAnchor="middle"
          dominantBaseline="middle"
          x={x}
          y={center - handleHeight * 1.4}
          fill="white"
        >
          {value}
        </text>
      </>
    )
  }
}

@withParentSize
@inject('state')
@observer
export class Timeline extends Component {
  svg = React.createRef()
  dimensions = {}
  interval

  componentDidMount() {
    const { filters } = this.props.state

    if (filters.isTimelinePlaying) {
      this.play()
    }

    this.disposeReaction = reaction(
      () => filters.isTimelinePlaying,
      isTimelinePlaying => {
        if (isTimelinePlaying) {
          this.play()
        } else {
          this.stop()
        }
      }
    )

    this.calculateDimensions()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.width !== this.props.width) {
      this.calculateDimensions()
    }
  }

  componentWillUnmount() {
    this.stop()
    this.disposeReaction()
  }

  calculateDimensions = () => {
    this.dimensions = this.svg.current.getBoundingClientRect()
  }

  play = () => {
    const { filters, data } = this.props.state

    // restart at the beginning
    if (filters.year === data.yearsExtent[1]) {
      filters.setYear(data.yearsExtent[0])
    }

    this.interval = setInterval(this.setNextYear, TIMELINE_INTERVAL)
  }

  stop = () => {
    const { filters } = this.props.state

    filters.stopTimeline()
    clearInterval(this.interval)
  }

  setNextYear = () => {
    const { filters, data } = this.props.state

    const nextYear = data.years[data.years.indexOf(filters.year) + 1]
    filters.setYear(nextYear)

    // stop at the end
    if (nextYear === data.yearsExtent[1]) {
      this.stop()
    }
  }

  get xScale() {
    const { data } = this.props.state
    const { parentWidth: width } = this.props

    return scaleLinear()
      .domain(data.yearsExtent)
      .range([0, width])
      .clamp(true)
    // .nice(true)
  }

  selectYear = e => {
    const { filters, data } = this.props.state

    // stop the timeline if it's playing
    if (filters.isTimelinePlaying) {
      this.stop()
    }

    const clientX = e.clientX || e.touches[0].clientX

    const newYear = Math.round(this.xScale.invert(clientX - this.dimensions.left))

    const closestYear = data.years.reduce((prev, curr) =>
      Math.abs(curr - newYear) < Math.abs(prev - newYear) ? curr : prev
    )

    filters.setYear(closestYear)
  }

  render() {
    const { filters, ui, data } = this.props.state
    const { parentWidth: width, className = '' } = this.props

    const height = TIMELINE_HEIGTH
    const center = height / 2
    const handleHeight = height * 0.22
    const clickableHeight = ui.isMobile ? height : height * 0.5

    return (
      <svg
        ref={this.svg}
        className={`${className} db overflow-visible`}
        width={width}
        height={height}
      >
        <g>
          {/* the horizontal line */}
          <line x1={0} y1={center} x2={width} y2={center} stroke="white" opacity={0.4} />
          <Ticks
            xScale={this.xScale}
            center={center}
            handleHeight={handleHeight}
            allTicksNumber={data.years.length}
          />
        </g>
        {/* the clickable area */}
        <rect
          x={0}
          y={center - clickableHeight / 2}
          width={width}
          height={clickableHeight}
          className="pointer tap-color-none"
          onClick={this.selectYear}
          onTouchMove={this.selectYear}
          fill="transparent"
          stroke="none"
        />
        <Handle
          xScale={this.xScale}
          center={center}
          handleHeight={handleHeight}
          value={filters.year}
          onChange={this.selectYear}
          fill={filters.isTimelinePlaying ? PALETTE.ACCENT : 'white'}
          className={ui.isMobile ? 'pointer-events-none' : ''}
        />
      </svg>
    )
  }
}
