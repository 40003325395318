import React from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { MapYeeHaw } from './MapYeeHaw'
import { Search } from './Search'
import { Title } from './Title'
import { Values } from './Values'
import { CategoryFilter } from './CategoryFilter'
import { Chapters } from './Chapters'
import { PlayButton } from './PlayButton'
import { Toggle } from './Toggle'
import { Timeline } from './Timeline'
import { ReactComponent as TexasLogoSmall } from '../assets/texas-logo-small.svg'
import { Description } from './Description'
import { Distribution } from './Distribution'
import { TIMELINE_HEIGTH, MAP_ANIMATION_DURATION } from '../constants'
import { Legend } from './Legend'

@inject('state')
@observer
export class App extends React.Component {
  componentDidMount() {
    const { map, filters } = this.props.state

    map.reset()
    filters.reset()

    setTimeout(map.play, 1000)
    setTimeout(filters.playTimeline, 1000 + MAP_ANIMATION_DURATION + 300)
  }

  render() {
    const { ui } = this.props.state

    if (ui.isMobile) {
      return (
        <div className="fixed h-100 w-100 flex flex-column">
          <div className="flex-auto overflow-y-auto pa3 flex flex-column">
            <div className="flex-none flex items-center">
              <Link to={`${process.env.SUBDIRECTORY}/`}>
                <TexasLogoSmall className="flex-none w2 mr3 white" />
              </Link>
              <Title className="flex-auto truncate" />
            </div>
            <Search className="flex-none mt3" />
            <div className="flex-none mt3 ph3">
              <div className="aspect-ratio aspect-ratio--1x1">
                <div className="absolute absolute--fill">
                  <MapYeeHaw />
                </div>
              </div>
            </div>
            <Values className="flex-none mv3" />
            <div className="flex-none ph3 mt-auto">
              <Timeline />
            </div>
          </div>
          <div className="flex-none h0 blue-gradient-scroll" />
          <Chapters className="flex-none ph3 pb3 pt2" />
        </div>
      )
    } else {
      return (
        <div className="vh-100 flex flex-column pl4 pb4">
          <div className="flex-auto flex">
            <div className="flex-60 pr4 pt4 pb3 flex flex-column">
              <div className="flex-none mb3">
                <Link to={`${process.env.SUBDIRECTORY}/`}>
                  <TexasLogoSmall className="white" style={{ height: '2.5rem' }} />
                </Link>
              </div>
              <div className="flex-auto relative">
                <div className="absolute absolute--fill">
                  <MapYeeHaw />
                </div>
                <Legend />
                <Toggle />
              </div>
            </div>
            <div className="flex-40 flex flex-column">
              <div className="flex-auto overflow-y-auto pt4 pr4">
                <Description />
                <CategoryFilter className="flex-none mt4" />
                <Search className="mt4" />
                <Values className="mt4" />
                <Distribution className="mt4" />
                <div className="mt4" />
              </div>
              <div className="flex-none h0 blue-gradient-scroll" />
            </div>
          </div>

          <div className="flex-none">
            <div className="flex items-center">
              <Title className="flex-60" />

              <div className="flex-40 flex items-center pr4">
                <PlayButton className="flex-none mr3" />
                <div className="flex-auto mh3 relative" style={{ height: TIMELINE_HEIGTH }}>
                  <Timeline />
                </div>
              </div>
            </div>

            <Chapters className="flex-auto mt3" />
          </div>
        </div>
      )
    }
  }
}
