import React from 'react'
import { inject, observer } from 'mobx-react'
import { ReactComponent as PlayIcon } from '../assets/play.svg'
import { ReactComponent as PauseIcon } from '../assets/pause.svg'

@inject('state')
@observer
export class PlayButton extends React.Component {
  render() {
    const { className = '' } = this.props
    const { filters } = this.props.state

    const Icon = filters.isTimelinePlaying ? PauseIcon : PlayIcon
    //  active={filters.isTimelinePlaying}
    return (
      <div
        className={`
          ${className}
            pa3 ba b--white br-100 white pointer
          `}
        onClick={filters.toggleTimeline}
      >
        <Icon className="w1 h1 db" />
      </div>
    )
  }
}
