import React from 'react'
import { inject, observer } from 'mobx-react'
import { SLIDES_TRANSITION_TIME } from '../../constants'

@inject('state')
@observer
export class SlideType3 extends React.Component {
  container = React.createRef()
  alternate = false

  componentDidMount() {
    const { mountedFunction } = this.props.slide
    const { ui } = this.props.state

    setTimeout(() => ui.setMounted(true), SLIDES_TRANSITION_TIME)
    if (mountedFunction) mountedFunction()

    // FUCK YOU SAFARI
    const IS_SAFARI =
      /Safari|FBAN|FBAV|LinkedIn/.test(navigator.userAgent) &&
      /Apple Computer/.test(navigator.vendor)
    if (IS_SAFARI) {
      const svgs = [...this.container.current.querySelectorAll('svg')]
      this.interval = setInterval(() => {
        svgs.forEach(svg => {
          svg.style.display = 'none'
          svg.style.display = 'block'
        })
        this.alternate = !this.alternate
      }, 10)

      setTimeout(() => clearInterval(this.interval), 4000)
    }
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval)
  }

  render() {
    const {
      slide,
      slide: {
        mountedTextStyle,
        unmountedTextStyle,
        mountedChartStyle,
        unmountedChartStyle,
        chartOnTop: ChartOnTop,
      },
      className = '',
    } = this.props
    const { ui } = this.props.state

    const Chart = ui.isMobile ? slide.chartMobile : slide.chartDesktop

    return (
      <div className={`${className} flex flex-column`} ref={this.container}>
        {slide.content && (
          <div className="flex-none flex">
            <div
              className={`
            mb4
            ${ui.isMobile ? '' : 'flex-50'}
            ${ui.isBigScreen ? 'f2' : 'f3'}
            lh-sarah
          `}
              style={{
                fontSize: ui.isMobile ? '4vw' : '',
                ...(ui.mounted ? mountedTextStyle : unmountedTextStyle),
              }}
            >
              {slide.content}
            </div>
          </div>
        )}
        <div
          className={`flex-auto relative`}
          style={ui.mounted ? mountedChartStyle : unmountedChartStyle}
        >
          <div
            className={`
            absolute absolute--fill
            ${ui.isMobile && ChartOnTop ? 'overflow-x-auto' : ''}
            ${slide.content ? (ui.isBigScreen ? 'pa5' : !ui.isMobile ? 'pa4' : '') : ''}
          `}
          >
            {ChartOnTop && <img src={ChartOnTop} className="h-25 w-100" />}
            <Chart
              className={`${ChartOnTop ? 'h-75' : 'h-100'} w-100`}
              style={{ width: ui.isMobile && ChartOnTop ? '300%' : '' }}
            />
          </div>
        </div>
      </div>
    )
  }
}
