import React from 'react'

export function Button({ children, active, className = '', big, style = {}, ...props }) {
  return (
    <button
      className={`
        input-reset bg-transparent outline-0
        ba b--white white br-100 ttu semi-b
        transition--fast
        ${big ? 'f3 hover-accent hover-b--accent pointer' : 'f6'}
        ${active || big ? '' : `pointer o-40 hover-o-60 active-o-100`}
        ${className}
      `}
      style={{ ...style, padding: big ? `2.5rem 3rem` : `0.75rem 1.5rem` }}
      {...props}
    >
      {children}
    </button>
  )
}
