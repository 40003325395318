export function computeSquarePath({ maxValue, value }) {
  const topLeft = `${-maxValue / 2} ${-maxValue / 2 + maxValue - value}`
  const topRight = `${maxValue / 2} ${-maxValue / 2 + maxValue - value}`
  const bottomRight = ` ${maxValue / 2} ${maxValue / 2}`
  const bottomLeft = `${-maxValue / 2} ${maxValue / 2}`

  return `M ${topLeft} L ${topRight} L ${bottomRight} L ${bottomLeft} Z`
}

export function computeTrianglePath({ maxValue, value, isPositive }) {
  const top = `${(maxValue / 2) * (isPositive ? 1 : -1)} ${-maxValue / 2 + maxValue - value}`
  const bottomRight = ` ${maxValue / 2} ${maxValue / 2}`
  const bottomLeft = `${-maxValue / 2} ${maxValue / 2}`

  return `M ${top} L ${bottomRight} L ${bottomLeft} Z`
}
