import React from 'react'
import { inject, observer } from 'mobx-react'
import { mapRange } from 'canvas-sketch-util/math'
import { upperFirst } from 'lodash'
import { ReactComponent as LegendRatio } from '../assets/legend-ratio.colors.svg'
import { ReactComponent as LegendCumulative } from '../assets/legend-cumulative.colors.svg'
import { formatNumber, formatRatio } from '../lib/string-utils'
import {
  CHAPTER_HEALTH,
  LEGENDS,
  MAP_DATA_APPEAR_FROM,
  UNIT_DEATHS,
  CATEGORY_PREMATURE_DEATHS,
} from '../constants'

// the legend square can't be smaller than MIN_SQUARE_DIMENSION pixels
const MIN_SQUARE_DIMENSION = 33

@inject('state')
@observer
export class Legend extends React.Component {
  render() {
    const { className = '' } = this.props
    const { map, filters } = this.props.state

    const legendSquareDimension = Math.max(map.cellSize, MIN_SQUARE_DIMENSION)

    return (
      <div
        className={`absolute transition--fast f0 ${className}`}
        style={{
          opacity: mapRange(map.easedProgress, MAP_DATA_APPEAR_FROM, 1, 0, 1, true),
          left: '65%',
          top: 0,
        }}
      >
        <div className="f6 mb2 semi-b" style={{ width: '10rem' }}>
          {filters.category === CATEGORY_PREMATURE_DEATHS
            ? upperFirst(UNIT_DEATHS)
            : LEGENDS[filters.activeChapter]}
        </div>
        {filters.isRatio ? (
          <div className="dib relative">
            <LegendRatio
              className="db overflow-visible"
              style={{ height: legendSquareDimension }}
            />
            <div
              className="absolute top-100 left-0 mt2 f7 tc"
              style={{ transform: `translateX(calc(-50% + ${legendSquareDimension / 2}px))` }}
            >
              {formatRatio(0)}
            </div>
            <div
              className="absolute top-100 right-0 mt2 f7 tc"
              style={{ transform: `translateX(calc(50% - ${legendSquareDimension / 2}px))` }}
            >
              {formatRatio(filters.maxRatio)}
            </div>
          </div>
        ) : (
          <div className="flex">
            <LegendCumulative
              className="db overflow-visible"
              style={{ height: legendSquareDimension }}
            />
            <div className="ml2 flex flex-column justify-between f6" style={{ lineHeight: 1 }}>
              <div>
                {formatNumber(map.cellScale.domain()[1])}
                {filters.activeChapter === CHAPTER_HEALTH &&
                  filters.category !== CATEGORY_PREMATURE_DEATHS &&
                  '%'}
              </div>
              <div>
                {formatNumber(map.cellScale.domain()[0])}
                {filters.activeChapter === CHAPTER_HEALTH &&
                  filters.category !== CATEGORY_PREMATURE_DEATHS &&
                  '%'}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}
