import React from 'react'
import { inject, observer } from 'mobx-react'
import { SLIDES_TRANSITION_TIME } from '../../constants'

@inject('state')
@observer
export class SlideType2 extends React.Component {
  componentDidMount() {
    const { mountedFunction } = this.props.slide
    const { ui } = this.props.state

    setTimeout(() => ui.setMounted(true), SLIDES_TRANSITION_TIME)
    if (mountedFunction) mountedFunction()
  }

  render() {
    const {
      slide,
      slide: { mountedTextStyle, unmountedTextStyle, mountedChartStyle, unmountedChartStyle },
      className = '',
    } = this.props
    const { ui } = this.props.state

    const Chart = slide.chart

    return ui.isMobile ? (
      <div className={`${className} flex flex-column`}>
        <div
          className={`
            mb4
            flex-none
            ${ui.isBigScreen ? 'f2' : 'f3'}
            lh-sarah
          `}
          style={{
            fontSize: ui.isMobile ? '4vw' : '',
            ...(ui.mounted ? mountedTextStyle : unmountedTextStyle),
          }}
        >
          {slide.content}
        </div>
        <div className="flex-auto">
          <Chart
            style={ui.mounted ? mountedChartStyle : unmountedChartStyle}
            className="w-100 h-100"
          />
        </div>
      </div>
    ) : (
      <div className={`${className} flex`}>
        <div className="flex-auto flex">
          <div
            className={`flex-40 lh-sarah ${ui.isBigScreen ? 'f2' : 'f3'}`}
            style={ui.mounted ? mountedTextStyle : unmountedTextStyle}
          >
            {slide.content}
          </div>
          <div className="flex-60 ml-auto flex flex-column">
            <Chart className="flex-90 mt-auto" />
          </div>
        </div>
      </div>
    )
  }
}
