import React from 'react'
import { inject, observer, Provider as MobxProvider } from 'mobx-react'
import { Stage } from 'react-konva'
import { withParentSize } from '@vx/responsive'
import { MapBackground } from './MapBackground'
import { MapCounties } from './MapCounties'
import { MapData } from './MapData'
import { MapSelected } from './MapSelected'
import { MapCountiesNoData } from './MapCountiesNoData'

@withParentSize
@inject('state')
@observer
export class MapYeeHaw extends React.Component {
  componentDidMount() {
    const { parentWidth, parentHeight } = this.props
    const { map } = this.props.state

    map.setWidth(parentWidth)
    map.setHeight(parentHeight)
  }

  componentDidUpdate(prevProps) {
    const { parentWidth, parentHeight } = this.props
    const { map } = this.props.state

    if (parentWidth !== prevProps.parentWidth) {
      map.setWidth(parentWidth)
    }

    if (parentHeight !== prevProps.parentHeight) {
      map.setHeight(parentHeight)
    }
  }

  render() {
    const { className = '' } = this.props
    const { map } = this.props.state

    if (map.dimension === 0) return null

    return (
      <Stage
        width={map.dimension}
        height={map.dimension}
        className={`h-100 flex flex-center ${className}`}
      >
        <MobxProvider state={this.props.state}>
          <MapBackground />
          <MapCountiesNoData />
          <MapCounties />
          <MapData />
          <MapSelected />
        </MobxProvider>
      </Stage>
    )
  }
}
