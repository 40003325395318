import { types as t, applySnapshot, getRoot } from 'mobx-state-tree'
import { debounce } from 'lodash'
import { SLIDES_TRANSITION_TIME } from '../constants'
import { slides } from '../components/Slides'

export const UI = t
  .model('UI', {
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    mounted: false,
    isSlidingDown: false,
    isSlidingUp: false,
  })
  .views(self => ({
    get isMobile() {
      return self.windowWidth < 1024
    },
    get isBigScreen() {
      return self.windowWidth > 2000
    },
  }))
  .actions(self => ({
    reset() {
      applySnapshot(self, {})
    },
    afterCreate() {
      window.addEventListener('resize', debounce(self.computePageSize, 50))
    },
    computePageSize() {
      self.windowWidth = window.innerWidth
      self.windowHeight = window.innerHeight
    },
    setMounted(value) {
      self.mounted = value
    },
    setSliding(upOrDownOrNone) {
      if (upOrDownOrNone === 'up') {
        self.isSlidingUp = true
        self.isSlidingDown = false
      } else if (upOrDownOrNone === 'down') {
        self.isSlidingUp = false
        self.isSlidingDown = true
      } else {
        self.isSlidingUp = false
        self.isSlidingDown = false
      }
    },
    transitionSlide(index) {
      const { routing, ui } = getRoot(self)
      const root = process.env.SUBDIRECTORY

      const to =
        index === 0
          ? `${root}/`
          : index === slides.length + 1
            ? `${root}/explorer`
            : `${root}/intro/${index}`

      // HACK, sorry man
      const currentIndex = Number(window.location.pathname.split('/').slice(-1)[0]) - 1

      self.setSliding(index > currentIndex ? 'down' : 'up')

      const transitionTimeout = setTimeout(() => {
        ui.setMounted(false)
        setTimeout(() => {
          routing.push(to)
          self.setSliding(null)
        }, SLIDES_TRANSITION_TIME)
      }, SLIDES_TRANSITION_TIME)

      return function cancel() {
        ui.setMounted(false)
        if (transitionTimeout) clearInterval(this.transitionTimeout)
      }
    },
  }))
