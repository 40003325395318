import React from 'react'
import { inject, observer } from 'mobx-react'
import { formatNumber, formatRatio } from '../lib/string-utils'
import { NO_DATA_STRING } from '../constants'

@inject('state')
@observer
export class Values extends React.Component {
  render() {
    const { className = '' } = this.props
    const { filters, data, ui } = this.props.state

    return (
      <div className={`${className} flex lh-title ${ui.isMobile ? '' : 'f4'}`}>
        <div className="flex-50">
          <div className="">Texas</div>
          <div className="mt1">
            {formatNumber(filters.texasFiltered)} {filters.activeUnit}
          </div>
          {filters.year !== data.firstYear && (
            <div className="mt1">
              {formatRatio(filters.texasFilteredRatio)} from {data.firstYear}
            </div>
          )}
        </div>

        {filters.isSelected && (
          <div className="flex-50 accent">
            <div className="">{filters.selectedCounty}</div>
            <div className="mt1">
              {filters.selectedCountyValue === NO_DATA_STRING
                ? NO_DATA_STRING
                : `${formatNumber(filters.selectedCountyValue)} ${filters.activeUnit}`}
            </div>
            {filters.selectedCountyValue !== NO_DATA_STRING &&
              filters.year !== data.firstYear &&
              !Number.isNaN(filters.selectedCountyRatio) && (
              <div className="mt1">
                {formatRatio(filters.selectedCountyRatio)} from {data.firstYear}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}
