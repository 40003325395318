import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button } from '../ui/Button'
import { CATEGORY_STRING } from '../constants'

@inject('state')
@observer
export class CategoryFilter extends React.Component {
  render() {
    const { className = '' } = this.props
    const { filters } = this.props.state

    if (filters.displayCategories.length <= 1) {
      return null
    }

    return (
      <div className={`${className}`}>
        <div className="mb3 f6 ttu">Filter by category</div>
        {filters.displayCategories.map(category => (
          <Button
            className="dib mr2 mb2"
            active={category === filters.category}
            onClick={() => filters.setCategory(category)}
            key={category}
          >
            {category.replace(CATEGORY_STRING, '')}
          </Button>
        ))}
      </div>
    )
  }
}
