import React from 'react'
import { inject, observer } from 'mobx-react'
import { parse, unparse } from 'papaparse'
import { PALETTE } from '../constants'
import { saveSvg } from '../lib/dom-utils'

@inject('state')
@observer
export class SvgBuilder extends React.Component {
  state = {
    csvString: '',
    tempCsvString: '',
  }

  componentDidMount() {
    const { map, data } = this.props.state

    map.setWidth(500)
    map.setHeight(500)

    const csvString = unparse(
      data.counties.map(county => ({ county, highlighted: Math.random() > 0.05 ? '' : 1 }))
    )
    this.setState({ csvString, tempCsvString: csvString })
  }

  render() {
    const { map } = this.props.state

    if (map.dimension === 0) return null

    const highlightedData = parse(this.state.csvString, { header: true }).data

    return (
      <div className="flex items-center">
        <svg width={map.dimension} height={map.dimension} className={`bg-gray`}>
          <g id="sqaures">
            {map.geojson.features.map(feature => {
              const datum = highlightedData.find(o => o.county === feature.properties.COUNTY)

              if (!datum) {
                alert(`County ${feature.properties.COUNTY} not found`)
                return null
              }

              const { highlighted } = datum
              if (!highlighted) return null

              return (
                <path
                  key={feature.properties.COUNTY}
                  d={map.interpolators[feature.properties.COUNTY][map.interpolators.length - 1]}
                  transform={`translate(${feature.geometry.scaledGridDisposition[0] -
                    feature.geometry.projectedCentroid[0]}, ${feature.geometry
                    .scaledGridDisposition[1] - feature.geometry.projectedCentroid[1]})`}
                  fill={PALETTE.ACCENT}
                  stroke={'none'}
                />
              )
            })}
          </g>

          <g id="grid-background">
            {map.geojson.features.map(feature => (
              <path
                key={feature.properties.COUNTY}
                d={map.interpolators[feature.properties.COUNTY][map.interpolators.length - 1]}
                transform={`translate(${feature.geometry.scaledGridDisposition[0] -
                  feature.geometry.projectedCentroid[0]}, ${feature.geometry
                  .scaledGridDisposition[1] - feature.geometry.projectedCentroid[1]})`}
                fill={'none'}
                stroke={'white'}
                strokeWidth={2}
              />
            ))}
          </g>
        </svg>
        <div className="mh5 f3 flex flex-column">
          <button
            className="mb5"
            onClick={() => {
              const svgString = document.querySelector('svg').outerHTML
              saveSvg('texas.svg', svgString)
            }}
          >
            Download svg
          </button>

          <button
            onClick={() => {
              this.setState({ csvString: this.state.tempCsvString })
            }}
          >
            ⬅️ update map
          </button>
        </div>
        <textarea
          value={this.state.tempCsvString}
          onChange={e => {
            this.setState({ tempCsvString: e.target.value })
          }}
          rows="30"
          cols="60"
        />
      </div>
    )
  }
}
