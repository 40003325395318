import React from 'react'
import { inject, observer } from 'mobx-react'
import { Layer, Path } from 'react-konva'
import { reaction } from 'mobx'
import { PALETTE, NO_DATA_STRING } from '../constants'

@inject('state')
@observer
export class MapCountiesNoData extends React.Component {
  layer = React.createRef()

  get canvas() {
    if (!this.layer.current) {
      return null
    }

    return this.layer.current.getCanvas()._canvas
  }

  componentDidMount() {
    const { filters } = this.props.state

    this.setOpacity()
    this.disposeReaction = reaction(() => filters.isSelected, this.setOpacity)
  }

  componentWillUnmount() {
    this.disposeReaction()
  }

  setOpacity = () => {
    const { filters } = this.props.state

    this.canvas.style.opacity = filters.isSelected ? 0.01 : 0.04
  }

  render() {
    const { map, filters } = this.props.state

    // TODO remove duplication from this component

    return (
      <Layer ref={this.layer}>
        {map.geojson.features.map(
          feature =>
            filters.countiesFilteredHashMap[feature.properties.COUNTY] === NO_DATA_STRING && (
              <Path
                key={feature.properties.COUNTY}
                data={
                  map.interpolators[feature.properties.COUNTY][
                    Math.round(
                      map.easedProgress * (map.interpolators[feature.properties.COUNTY].length - 1)
                    )
                  ]
                }
                x={
                  map.easedProgress *
                  (feature.geometry.scaledGridDisposition[0] -
                    feature.geometry.projectedCentroid[0])
                }
                y={
                  map.easedProgress *
                  (feature.geometry.scaledGridDisposition[1] -
                    feature.geometry.projectedCentroid[1])
                }
                fill={'white'}
                stroke={PALETTE.BLUE}
                strokeWidth={map.dimension < 600 ? 2 : 3}
                onClick={() =>
                  filters.isSelected && filters.search === feature.properties.COUNTY
                    ? filters.resetSearch()
                    : filters.setSearch(feature.properties.COUNTY)
                }
                onMouseEnter={() => {
                  document.body.style.cursor = 'pointer'
                }}
                onMouseLeave={() => {
                  document.body.style.cursor = 'default'
                }}
                preventDefault={false}
              />
            )
        )}
      </Layer>
    )
  }
}
