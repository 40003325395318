import React from 'react'
import { inject, observer } from 'mobx-react'
import { SLIDES_TRANSITION_TIME } from '../../constants'

@inject('state')
@observer
export class SlideType4 extends React.Component {
  componentDidMount() {
    const { mountedFunction } = this.props.slide
    const { ui } = this.props.state

    setTimeout(() => ui.setMounted(true), SLIDES_TRANSITION_TIME)
    if (mountedFunction) mountedFunction()
  }

  render() {
    const {
      slide,
      slide: { mountedTextStyle, unmountedTextStyle, mountedNumberStyle, unmountedNumberStyle },
      className = '',
    } = this.props
    const { ui } = this.props.state

    return (
      <div className={`${className} flex flex-column`}>
        <div className="flex-none flex">
          <div
            className={`
              mb4
              ${ui.isMobile ? '' : 'flex-50'}
              ${ui.isBigScreen ? 'f2' : 'f3'}
              lh-sarah
            `}
            style={{
              fontSize: ui.isMobile ? '4vw' : '',
              ...(ui.mounted ? mountedTextStyle : unmountedTextStyle),
            }}
          >
            {slide.content}
          </div>
        </div>
        <div className={`flex-auto flex flex-wrap justify-center`}>
          {slide.numbers.map((n, i) => (
            <div
              key={i}
              className="number flex flex-column items-center justify-center mh5 tc"
              style={ui.mounted ? mountedNumberStyle : unmountedNumberStyle}
            >
              <div
                className="fw8"
                style={{
                  fontSize: ui.isBigScreen
                    ? '12rem'
                    : ui.isMobile
                      ? slide.numbers.length > 1
                        ? '13vw'
                        : '4rem'
                      : '7.5rem',
                }}
              >
                {n.number}
              </div>
              <div
                className={` ${ui.isBigScreen ? 'f2' : 'f3'}`}
                style={{ fontSize: ui.isMobile ? '4vw' : '' }}
              >
                {n.subtitle}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}
