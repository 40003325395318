import React from 'react'
import { inject, observer } from 'mobx-react'
import { flow } from 'lodash'
import { ReactComponent as ToggleTiled } from '../assets/toggle-tiled.svg'
import { ReactComponent as ToggleReal } from '../assets/toggle-real.svg'

@inject('state')
@observer
export class Toggle extends React.Component {
  render() {
    const { className = '' } = this.props
    const { map } = this.props.state

    if (map.dimension === 0) return null

    return (
      <div className={`${className} absolute left-0`} style={{ bottom: '10%' }}>
        <div
          className={`
            pointer f4 flex-none transition--fast relative show-text-on-hover-container
            ${!map.isAnimationReverse ? 'o-40 hover-o-60' : ''}
          `}
          onClick={!map.isAnimationReverse ? flow([map.stop, map.play]) : null}
        >
          <ToggleReal className="db white" style={{ width: map.cellSize * 1.2 }} />
          <div
            className="absolute absolute--center-vertical ttu f6 pointer-events-none nowrap"
            style={{ left: `calc(100% + 1rem)` }}
          >
            <div className="show-text-on-hover transition--fast will-change-transform">
              Real map
            </div>
          </div>
        </div>
        <div
          className={`
            pointer f4 flex-none transition--fast relative show-text-on-hover-container
            mt3
            ${map.isAnimationReverse ? 'o-40 hover-o-60' : ''}
          `}
          onClick={map.isAnimationReverse ? flow([map.stop, map.play]) : null}
        >
          <ToggleTiled className="db white" style={{ width: map.cellSize * 1.2 }} />
          <div
            className="absolute absolute--center-vertical ttu f6 pointer-events-none nowrap"
            style={{ left: `calc(100% + 1rem)` }}
          >
            <div className="show-text-on-hover transition--fast will-change-transform">
              Tile map
            </div>
          </div>
        </div>
      </div>
    )
  }
}
