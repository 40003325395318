import { types as t } from 'mobx-state-tree'
import { RouterModel } from 'mst-react-router'
import { Map } from './Map'
import { Data } from './Data'
import { Filters } from './Filters'
import { UI } from './UI'

export const State = t
  .model('State', {
    routing: RouterModel,
    map: t.optional(Map, {}),
    data: t.optional(Data, {}),
    filters: t.optional(Filters, {}),
    ui: t.optional(UI, {}),
  })
  .actions(self => ({
    // reset the substates
    reset() {
      Object.keys(self).forEach(key => {
        self[key].reset && self[key].reset()
      })
    },
  }))
