import { types as t, applySnapshot } from 'mobx-state-tree'
import { extent } from 'd3-array'
import { uniq, orderBy, max } from 'lodash'
import {
  TEXAS_STATE,
  CATEGORY_STRING,
  SINGLE_CATEGORY_STRING,
  FIRST_DATASET,
  CHAPTERS,
  CHAPTER_POPULATION_ETHNICITY,
  CHAPTER_WATER,
  CHAPTER_TRAFFIC,
  CHAPTER_HEALTH,
  CHAPTER_EDUCATION,
} from '../constants'

export const OTHER_DATASETS = {
  [CHAPTER_POPULATION_ETHNICITY]: 'population-by-ethnicity',
  [CHAPTER_WATER]: 'water',
  [CHAPTER_TRAFFIC]: 'traffic',
  [CHAPTER_HEALTH]: 'health',
  [CHAPTER_EDUCATION]: 'education',
}

export function extractCategories(dataset) {
  const keys = Object.keys(dataset[0])
  const categories = keys.includes(SINGLE_CATEGORY_STRING)
    ? [SINGLE_CATEGORY_STRING]
    : keys.filter(k => k.startsWith(CATEGORY_STRING))

  if (categories.includes(`${CATEGORY_STRING}all`)) {
    categories.splice(categories.indexOf(`${CATEGORY_STRING}all`), 1)
    categories.unshift(`${CATEGORY_STRING}all`)
  }

  return categories
}

export function extractYears(dataset) {
  return orderBy(uniq(dataset.map(d => d.year)), Number, 'asc')
}

export const Data = t
  .model('Data', {
    dataRaw: t.frozen(FIRST_DATASET),
    datasets: t.map(t.frozen([])),
  })
  .views(self => ({
    get years() {
      return extractYears(self.dataRaw)
    },
    get yearsExtent() {
      return extent(self.years)
    },
    get firstYear() {
      return self.yearsExtent[0]
    },
    get lastYear() {
      return self.yearsExtent[1]
    },
    get categories() {
      return extractCategories(self.dataRaw)
    },
    get counties() {
      return uniq(self.countiesData.map(c => c.county)).filter(county => county !== TEXAS_STATE)
    },
    get countiesData() {
      return self.dataRaw.filter(datum => datum.county !== TEXAS_STATE)
    },
    get texasData() {
      return self.dataRaw.filter(datum => datum.county === TEXAS_STATE)
    },
    get maxValue() {
      return max(self.countiesData.flatMap(row => self.categories.map(c => row[c])))
    },
  }))
  .actions(self => ({
    // Load the other datasets async!
    afterCreate() {
      self.datasets.set(CHAPTERS[0], FIRST_DATASET)

      Object.keys(OTHER_DATASETS).forEach(async chapter => {
        if (chapter === CHAPTERS[0]) return

        const { default: dataset } = await import(`../data/${OTHER_DATASETS[chapter]}.csv`)
        self.setDataset(chapter, dataset)
      })
    },
    reset() {
      applySnapshot(self, {})
    },
    setDataset(key, dataset) {
      self.datasets.set(key, dataset)
    },
    setDataRaw(dataRaw) {
      self.dataRaw = dataRaw
    },
  }))
