import React from 'react'
import { inject, observer } from 'mobx-react'
import { scaleLinear } from 'd3-scale'
import { withParentSize } from '@vx/responsive'
import { formatNumber } from '../lib/string-utils'
import { PALETTE, DISTRIBUTION_HEIGTH, NO_DATA_STRING } from '../constants'

@withParentSize
@inject('state')
@observer
class DistributionLines extends React.Component {
  calculateAlignment = x => {
    const { parentWidth: width } = this.props

    switch (true) {
      case x <= width * 0.2:
        return 'left'
      case width * 0.2 < x && x <= width * 0.8:
        return 'center'
      case width * 0.8 < x:
        return 'right'
    }
  }

  render() {
    const { parentWidth: width, hovered, onHover } = this.props
    const { filters, data } = this.props.state

    const xScale = scaleLinear()
      .domain([filters.countiesFilteredFirst.value, filters.countiesFilteredLast.value])
      .range([0, width])

    const HOVER_AREA_WIDTH = 5

    return (
      <svg className="db overflow-visible" width="100%" height="100%">
        {data.counties.map((county, i) => {
          if (filters.countiesFilteredHashMap[county] === NO_DATA_STRING) {
            return null
          }

          const x = xScale(filters.countiesFilteredHashMap[county])
          const alignment = this.calculateAlignment(x)
          return (
            <React.Fragment key={i}>
              <line
                x1={x}
                y1={0}
                x2={x}
                y2={'100%'}
                stroke={'white'}
                strokeWidth={1}
                strokeOpacity={hovered === county ? 1 : 0.12}
              />
              <rect
                onMouseOver={() => onHover(county, x, alignment)}
                onClick={() => filters.setSearch(county)}
                x={x - HOVER_AREA_WIDTH / 2}
                y={0}
                width={HOVER_AREA_WIDTH}
                height={'100%'}
                opacity={0}
              />
            </React.Fragment>
          )
        })}
        {filters.selectedCounty &&
          filters.countiesFilteredHashMap[filters.selectedCounty] !== NO_DATA_STRING && (
          <line
            className="pointer-events-none"
            x1={xScale(filters.countiesFilteredHashMap[filters.selectedCounty])}
            y1={0}
            x2={xScale(filters.countiesFilteredHashMap[filters.selectedCounty])}
            y2={'100%'}
            stroke={PALETTE.ACCENT}
            strokeWidth={3}
          />
        )}
      </svg>
    )
  }
}

@inject('state')
@observer
export class Distribution extends React.Component {
  state = {
    hovered: undefined,
    hoveredX: undefined,
    alignment: undefined,
  }

  setHovered = (county, x, alignment) => {
    this.setState({ hovered: county, hoveredX: x, alignment })
  }

  resetHovered = () => {
    this.setState({ hovered: undefined, hoveredX: undefined, alignment: undefined })
  }

  render() {
    const { className = '' } = this.props
    const { hovered, hoveredX, alignment } = this.state
    const { filters } = this.props.state

    return (
      <div className="ph3 nh3" onMouseLeave={this.resetHovered}>
        <div className={`${className}`} style={{ height: DISTRIBUTION_HEIGTH }}>
          <DistributionLines hovered={hovered} onHover={this.setHovered} />
        </div>
        <div className="flex relative mt2" ref={this.container}>
          <div className="flex-50" style={{ opacity: hovered ? 0 : 1 }}>
            <div className="f6">{filters.countiesFilteredFirst.county}</div>
            <div className="f6 mt1 o-40">
              {formatNumber(filters.countiesFilteredFirst.value)} {filters.activeUnit}
            </div>
          </div>
          <div className="flex-50 tr" style={{ opacity: hovered ? 0 : 1 }}>
            <div className="f6">{filters.countiesFilteredLast.county}</div>
            <div className="f6 mt1 o-40">
              {formatNumber(filters.countiesFilteredLast.value)} {filters.activeUnit}
            </div>
          </div>
          {hovered && (
            <div
              className="absolute top-0 left-0 will-change-transform"
              style={{
                transform: `translateX(calc(${hoveredX}px + calc(${
                  alignment === 'center' ? -50 : alignment === 'left' ? 0 : -100
                }%)))`,
                textAlign: alignment,
              }}
            >
              <div className="f6">{hovered}</div>
              <div className="f6 mt1 o-40">
                {formatNumber(filters.countiesFilteredHashMap[hovered])} {filters.activeUnit}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}
