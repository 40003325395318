import React from 'react'
import { inject, observer } from 'mobx-react'
import { upperFirst } from 'lodash'
import { ReactComponent as CloseIcon } from '../assets/close.svg'
import { ReactComponent as SearchIcon } from '../assets/search.svg'

@inject('state')
@observer
export class Search extends React.Component {
  state = {
    opened: false,
    focusedOption: null,
  }

  searchRef = React.createRef()

  componentDidUpdate(props, state) {
    if (this.state.opened && !state.opened) {
      this.setMenuListeners()
    }
  }

  setMenuListeners = () => {
    document.body.addEventListener('mouseup', this.onOutsideClick, { passive: true, once: true })
    document.body.addEventListener('touchend', this.onOutsideClick, { passive: true, once: true })
  }

  onChange = e => {
    const { value } = e.target
    const { filters } = this.props.state

    this.setState({ opened: true, focusedOption: null }, () => this.setMenuListeners())
    filters.setSearch(value)
  }

  resetSearch = () => this.setState({ opened: false, focusedOption: null })

  onSelectCounty = c => {
    const { filters } = this.props.state

    filters.setSearch(c)
    this.resetSearch()
  }

  onOutsideClick = e => {
    if (this.searchRef.current.contains(e.target)) return
    this.resetSearch()
  }

  onClean = e => {
    const { filters } = this.props.state

    e.preventDefault()
    e.stopPropagation()

    filters.setSearch('')
    this.resetSearch()
  }

  scrollCountyIntoView = (name, block = 'center') => {
    const county = document.querySelector(`.search-${name}`)
    if (county) county.scrollIntoView({ behavior: 'smooth', block, inline: 'end' })
  }

  toggleMenu = () => {
    this.setState(state => ({
      opened: !state.opened,
    }))
  }

  focusOption(direction = 'down') {
    const {
      filters: { countiesSearched },
    } = this.props.state
    const { focusedOption } = this.state

    if (!countiesSearched.length) return

    let nextFocus = 0
    let focusedIndex = countiesSearched.indexOf(focusedOption)
    if (!focusedOption) focusedIndex = -1

    if (direction === 'up' && focusedIndex > 0) {
      nextFocus = focusedIndex - 1
    } else if (direction === 'down' && focusedIndex < countiesSearched.length - 1) {
      nextFocus = focusedIndex + 1
    }

    this.setState({ focusedOption: countiesSearched[nextFocus] }, () =>
      this.scrollCountyIntoView(countiesSearched[nextFocus])
    )
  }

  onHover = focusedOption => {
    this.setState({ focusedOption })
  }

  onKeyDown = e => {
    const { opened, focusedOption } = this.state
    const {
      filters: { countiesSearched },
    } = this.props.state
    const firstCounty = countiesSearched[0]

    switch (e.key) {
      case 'Enter':
        if (open) {
          if (!focusedOption) return
          this.onSelectCounty(focusedOption)
        }
        break
      case 'ArrowUp':
        if (opened) {
          if (!focusedOption) return
          this.focusOption('up')
        }
        break
      case 'ArrowDown':
        if (opened) {
          if (!focusedOption) {
            this.setState({ focusedOption: firstCounty })
          } else {
            this.focusOption('down')
          }
        } else {
          this.setState({
            opened: true,
            focusedOption: firstCounty,
          })
        }
        break
      default:
    }
  }

  render() {
    const { opened, focusedOption } = this.state
    const { className = '' } = this.props
    const { filters } = this.props.state

    const Icon = filters.search.length > 0 ? CloseIcon : SearchIcon

    return (
      <div className={`${className} relative`} ref={this.searchRef}>
        <label
          className={`flex items-center bb pb1`}
          onClick={this.toggleMenu}
          style={{ borderColor: 'rgba(255, 255, 255, 0.4)' }}
        >
          <input
            onKeyDown={this.onKeyDown}
            onChange={this.onChange}
            placeholder="Search county..."
            type="text"
            value={upperFirst(filters.search)}
            className={`
              flex-auto
              bg-transparent input-reset bn outline-0 white f4 medium pv1
              ${filters.isSelected ? 'accent' : ''}
            `}
          />

          <Icon
            className="flex-none h1 white pointer ml2 o-40"
            onClick={filters.search.length > 0 ? this.onClean : null}
          />
        </label>

        {opened && !filters.isSelected && (
          <div
            className={`absolute top-100 left-0 right-0 z-1 overflow-y-auto`}
            // 39 is the height of a cell
            style={{ maxHeight: 39 * 7 }}
          >
            <div className="relative">
              <div
                className="absolute absolute--fill bg-blue z--1"
                style={{ backdropFilter: 'blur(3px)', opacity: '0.9' }}
              />
              {filters.countiesSearched.map(c => (
                <div
                  onClick={() => this.onSelectCounty(c)}
                  onMouseEnter={() => this.onHover(c)}
                  className={`search-${c} pv2 pointer f4 ${focusedOption === c ? 'o-60' : ''}`}
                  key={c}
                >
                  {upperFirst(c)}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    )
  }
}
