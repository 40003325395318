import { types as t, applySnapshot, getRoot } from 'mobx-state-tree'
import { reaction } from 'mobx'
import { minBy, maxBy, max } from 'lodash'
import {
  CHAPTERS,
  UNITS,
  DATATYPE_RATIO,
  DATATYPES,
  MAX_RATIO,
  CHAPTER_EDUCATION,
  CATEGORY_STRING,
  FIRST_DATASET,
  CATEGORY_PREMATURE_DEATHS,
  UNIT_DEATHS,
  CHAPTER_WATER,
} from '../constants'
import { extractCategories, extractYears } from './Data'

export const Filters = t
  .model('Filters', {
    activeChapter: CHAPTERS[0],
    category: extractCategories(FIRST_DATASET)[0],
    year: extractYears(FIRST_DATASET)[0],
    isTimelinePlaying: false,
    search: '',
  })
  .views(self => ({
    get isSelected() {
      const { data } = getRoot(self)
      return data.counties.includes(self.search)
    },
    get selectedCounty() {
      return self.isSelected ? self.search : undefined
    },
    get countiesFiltered() {
      const { data } = getRoot(self)
      return data.countiesData
        .filter(row => row.year === self.year)
        .map(row => ({
          year: row.year,
          county: row.county,
          value: row.value || row[self.category],
        }))
    },
    get countiesFilteredFirstYear() {
      const { data } = getRoot(self)
      return data.countiesData
        .filter(row => row.year === data.firstYear)
        .map(row => ({
          year: row.year,
          county: row.county,
          value: row.value || row[self.category],
        }))
    },
    get texasFiltered() {
      const { data } = getRoot(self)
      return data.texasData
        .filter(row => row.year === self.year)
        .map(row => ({
          year: row.year,
          county: row.county,
          value: row.value || row[self.category],
        }))[0].value
    },
    get texasFilteredFirstYear() {
      const { data } = getRoot(self)
      return data.texasData
        .filter(row => row.year === data.firstYear)
        .map(row => ({
          year: row.year,
          county: row.county,
          value: row.value || row[self.category],
        }))[0].value
    },
    get texasFilteredRatio() {
      return self.texasFiltered / self.texasFilteredFirstYear
    },
    get countiesFilteredFirst() {
      return minBy(self.countiesFiltered, 'value')
    },
    get countiesFilteredLast() {
      return maxBy(self.countiesFiltered, 'value')
    },
    get countiesFilteredHashMap() {
      return self.countiesFiltered.reduce((acc, d) => {
        acc[d.county] = d.value
        return acc
      }, {})
    },
    get countiesFilteredFirstYearHashMap() {
      return self.countiesFilteredFirstYear.reduce((acc, d) => {
        acc[d.county] = d.value
        return acc
      }, {})
    },
    get countiesFilteredRatioHashMap() {
      const { data } = getRoot(self)

      return data.counties.reduce((acc, county) => {
        acc[county] =
          self.countiesFilteredHashMap[county] / self.countiesFilteredFirstYearHashMap[county]
        return acc
      }, {})
    },
    get isRatio() {
      return DATATYPES[self.activeChapter] === DATATYPE_RATIO
    },
    get maxRatio() {
      return MAX_RATIO[self.activeChapter]
    },
    get maxValueCategory() {
      const { data } = getRoot(self)

      return max(data.countiesData.map(row => row.value || row[self.category]))
    },
    get countiesSearched() {
      const { data } = getRoot(self)
      return data.counties.filter(c => c.toLowerCase().startsWith(self.search.toLowerCase()))
    },
    get selectedCountyValue() {
      return self.countiesFilteredHashMap[self.selectedCounty]
    },
    get selectedCountyRatio() {
      return self.countiesFilteredRatioHashMap[self.selectedCounty]
    },
    get activeUnit() {
      return self.category === CATEGORY_PREMATURE_DEATHS ? UNIT_DEATHS : UNITS[self.activeChapter]
    },
    get displayCategories() {
      const { data } = getRoot(self)

      const categories = data.categories.slice(0)

      // if it's education, return them sorted 😩
      if (self.activeChapter === CHAPTER_EDUCATION) {
        return [
          `${CATEGORY_STRING}Less Than HS`,
          `${CATEGORY_STRING}High School Diploma`,
          `${CATEGORY_STRING}Some College`,
          `${CATEGORY_STRING}Associate's Degree`,
          `${CATEGORY_STRING}Bachelor's Degree`,
          `${CATEGORY_STRING}Graduate Degree or More`,
        ]
      }

      // if it's water, return them sorted 😩
      if (self.activeChapter === CHAPTER_WATER) {
        return [`${CATEGORY_STRING}needs`, `${CATEGORY_STRING}supply`, `${CATEGORY_STRING}demand`]
      }

      return categories
    },
  }))
  .actions(self => ({
    afterCreate() {
      reaction(
        () => self.activeChapter,
        activeChapter => {
          const { data } = getRoot(self)
          data.setDataRaw(data.datasets.get(activeChapter))
          self.setCategory(self.displayCategories[0])
          self.setYear(data.firstYear)
        }
      )
    },
    reset() {
      applySnapshot(self, {})
    },
    setActiveChapter(activeChapter) {
      self.activeChapter = activeChapter
    },
    setCategory(category) {
      self.category = category
    },
    setYear(year) {
      self.year = year
    },
    toggleTimeline() {
      self.isTimelinePlaying = !self.isTimelinePlaying
    },
    stopTimeline() {
      self.isTimelinePlaying = false
    },
    playTimeline() {
      self.isTimelinePlaying = true
    },
    setSearch(value) {
      self.search = value
    },
    resetSearch() {
      self.search = ''
    },
  }))
