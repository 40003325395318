import React from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { ReactComponent as LogoTexasSmall } from './../assets/texas-logo-small.svg'
import { ReactComponent as Arrow } from './../assets/arrow.svg'
import { slides } from './Slides'

@inject('state')
@observer
export class SlidesMenu extends React.Component {
  cancelTransition = null

  onChangeSlide = (e, index) => {
    e.preventDefault()
    e.stopPropagation()

    const { ui } = this.props.state
    this.cancelTransition = ui.transitionSlide(index)
  }

  componentWillUnmount() {
    if (this.cancelTransition) {
      this.cancelTransition()
      this.cancelTransition = null
    }
  }

  render() {
    const { className = '', index, slidesNumber } = this.props
    const { ui, routing } = this.props.state

    return (
      <div className={`flex ${className}`}>
        <div className={`flex ${ui.isMobile ? 'flex-50' : 'flex-33'} items-center`}>
          <Link to={`${process.env.SUBDIRECTORY}/`}>
            <LogoTexasSmall className={`${ui.isMobile ? 'h2 mr3' : 'w3 mr4'} white`} />
          </Link>
          <Link
            to={`${process.env.SUBDIRECTORY}/explorer`}
            className={`
              link white outline-0 ttu fw6 f6 letter-spacing-small
              user-select-none nowrap
            `}
          >
            explore data
          </Link>
        </div>

        <div
          className={`flex items-center ${
            ui.isMobile ? 'justify-end flex-50' : 'justify-center flex-33'
          }`}
        >
          {/* UP */}
          <Link
            onClick={e => this.onChangeSlide(e, index)}
            to={
              index === 0
                ? `${process.env.SUBDIRECTORY}/`
                : `${process.env.SUBDIRECTORY}/intro/${index}`
            }
          >
            <Arrow
              className={`white rotate-180 transition ${ui.isSlidingUp ? 'accent' : ''}`}
              width="32"
            />
          </Link>

          {/* DOWN */}
          {index < slidesNumber - 1 && (
            <Link
              onClick={e => this.onChangeSlide(e, index + 2)}
              to={`${process.env.SUBDIRECTORY}/intro/${index + 2}`}
              className={`${ui.isMobile ? 'ml4' : 'ml5'}`}
            >
              <Arrow
                className={`white transition ${ui.isSlidingDown ? 'accent' : ''}`}
                width="32"
              />
            </Link>
          )}
        </div>

        {!ui.isMobile && routing.location.pathname.includes(slides.length) && (
          <div className={`flex-33 flex items-center justify-end`}>
            <a
              href={`${process.env.SUBDIRECTORY}/credits`}
              className="link white fw6 letter-spacing-small transition--fast o-40 hover-o-60 active-o-100"
            >
              Credits + sources
            </a>
          </div>
        )}
      </div>
    )
  }
}
