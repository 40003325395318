import React from 'react'

export class Credits extends React.Component {
  render() {
    // reaload window to go to the credits route
    window.location.reload()

    return null
  }
}
