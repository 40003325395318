import React from 'react'
import { inject, observer } from 'mobx-react'
import { Link, Redirect } from 'react-router-dom'
import { Slide } from './Slide'
import { SlidesMenu } from './SlidesMenu'
import { SlidesBar } from './SlidesBar'
import { Button } from '../ui/Button'
import Capitol from './../assets/charts/capitol.colors.svg'
import { ReactComponent as Slide1Chart } from './../assets/charts/s1.colors.svg'
import { ReactComponent as Slide2Chart } from './../assets/charts/s2.colors.svg'
import { ReactComponent as Slide3ChartMobile } from './../assets/charts/s3_mobile.colors.svg'
import { ReactComponent as Slide3ChartDesktop } from './../assets/charts/s3_desktop.colors.svg'
import { ReactComponent as Slide3BChartMobile } from './../assets/charts/s3B_mobile.colors.svg'
import { ReactComponent as Slide3BChartDesktop } from './../assets/charts/s3B_desktop.colors.svg'
import { ReactComponent as Slide4ChartMobile } from './../assets/charts/s4_mobile.colors.svg'
import { ReactComponent as Slide4ChartDesktop } from './../assets/charts/s4_desktop.colors.svg'
import { ReactComponent as Slide6ChartMobile } from './../assets/charts/s6_mobile.colors.svg'
import { ReactComponent as Slide6ChartDesktop } from './../assets/charts/s6_desktop.colors.svg'
import { ReactComponent as Slide7ChartMobile } from './../assets/charts/s7_mobile.colors.svg'
import { ReactComponent as Slide7ChartDesktop } from './../assets/charts/s7_desktop.colors.svg'
import { ReactComponent as Slide8Chart } from './../assets/charts/s8.colors.svg'
// import { ReactComponent as Slide12Chart } from './../assets/charts/s12.colors.svg'
import { ReactComponent as Slide10ChartMobile } from './../assets/charts/s10_mobile.colors.svg'
import { ReactComponent as Slide10ChartDesktop } from './../assets/charts/s10_desktop.colors.svg'
import { ReactComponent as SlideDisparitiesChartMobile } from './../assets/charts/sDisparities_mobile.colors.svg'
import { ReactComponent as SlideDisparitiesChartDesktop } from './../assets/charts/sDisparities_desktop.colors.svg'
import { ReactComponent as Slide13Chart } from './../assets/charts/s13.colors.svg'
import { ReactComponent as Slide14Chart } from './../assets/charts/s14.colors.svg'
import { ReactComponent as Slide15ChartMobile } from './../assets/charts/s15_mobile.colors.svg'
import { ReactComponent as Slide15ChartDesktop } from './../assets/charts/s15_desktop.colors.svg'
import { SLIDES_TRANSITION_TIME } from '../constants'
import { Swiper } from './Swiper'

const bigTextAnimation = {
  mountedTextStyle: { opacity: 1, transition: `all 1s ease` },
  unmountedTextStyle: { opacity: 0, transition: `all ${SLIDES_TRANSITION_TIME}ms ease` },
}

const topLeftTextAnimation = {
  mountedTextStyle: {
    opacity: 1,
    transform: `translate(0)`,
    transition: `all ${SLIDES_TRANSITION_TIME}ms ease`,
  },
  unmountedTextStyle: {
    opacity: 0,
    transform: `translateX(-30px)`,
    transition: `all ${SLIDES_TRANSITION_TIME}ms ease`,
  },
}

const chartAnimation = {
  mountedChartStyle: {
    opacity: 1,
    transition: `all ${SLIDES_TRANSITION_TIME}ms ease`,
  },
  unmountedChartStyle: {
    opacity: 0,
    transition: `all ${SLIDES_TRANSITION_TIME}ms ease`,
  },
}

const numbersAnimation = {
  mountedNumberStyle: {
    opacity: 0,
    transition: `all ${SLIDES_TRANSITION_TIME * 5}ms ease`,
  },
  unmountedNumberStyle: {
    opacity: 0,
    transition: `all ${SLIDES_TRANSITION_TIME * 5}ms ease`,
  },
}

const defaultTextImageAnimation = {
  ...topLeftTextAnimation,
  ...chartAnimation,
}

const defaultNumberAnimation = {
  ...topLeftTextAnimation,
  ...numbersAnimation,
}

const numbersMountedFunction = () => {
  const numbers = document.querySelectorAll('.number')
  setTimeout(() => {
    numbers.forEach((number, i) => setTimeout(() => (number.style.opacity = 1), i * 500))
  }, SLIDES_TRANSITION_TIME + 200)
}

const mapOpacityMountedFunction = (time = 2000) => {
  const states = document.querySelectorAll('#Counties path, #Map path')

  states.forEach(el => (el.style.opacity = 0))

  setTimeout(
    () =>
      states.forEach(el => {
        el.style.transition = `opacity ${time}ms linear`
        el.style.opacity = 1
      }),
    0
  )
}

const linesAndLabelsMountedFunction = () => {
  const lines = document.querySelectorAll('#Lines path')
  const labels = document.querySelectorAll('#Labels text')

  lines.forEach(line => {
    line.style.strokeDasharray = 1000
    line.style.strokeDashoffset = 1000
  })
  labels.forEach(label => {
    label.style.opacity = 0
    label.style.transition = 'opacity 300ms linear'
  })

  setTimeout(() => {
    lines.forEach(line => {
      line.style.animation = 'dash 3s linear'
    })

    setTimeout(() => {
      lines.forEach(line => {
        line.style.strokeDasharray = 0
        labels.forEach((label, i) =>
          setTimeout(() => {
            label.style.opacity = 1
          }, i * 200)
        )
      })
    }, 2000)
  }, SLIDES_TRANSITION_TIME)
}

export const slides = [
  {
    type: 'text-and-image',
    content: (
      <div>
        Texas has come a long way since our founding in 1836. When the U.S. Census first began
        collecting estimates of the state population in 1850, Texas had just over{' '}
        <span className="fw8">200,000 residents</span> living in{' '}
        <span className="fw8">79 counties.</span>
      </div>
    ),
    chart: Slide1Chart,
    ...defaultTextImageAnimation,
    mountedFunction: () => {
      const states = document.querySelectorAll('#Counties path')

      states.forEach(el => (el.style.opacity = 0))

      setTimeout(
        () =>
          states.forEach((el, i) => {
            el.style.transition = 'opacity 1s ease'
            setTimeout(() => {
              if (i) el.style.opacity = 1
            }, i * 10)
          }),
        0
      )
    },
  },
  {
    type: 'text-and-image',
    content: (
      <div>
        Today, Texas is the second largest state in America with a population of over{' '}
        <span className="fw8">28 million </span>
        living in <span className="fw8">254 counties</span>.
      </div>
    ),
    chart: Slide2Chart,
    ...defaultTextImageAnimation,
    mountedFunction: () => {
      mapOpacityMountedFunction()
      const triangle = document.querySelector('#Triangle path')
      triangle.style.strokeDasharray = 1000
      triangle.style.strokeDashoffset = 1000

      setTimeout(() => {
        triangle.style.animation = 'dash 3s linear'
        setTimeout(() => (triangle.style.strokeDasharray = 0), 3000)
      }, SLIDES_TRANSITION_TIME)
    },
  },
  // 3
  {
    type: 'text-and-image-des-mob',
    content: (
      <div className="text">
        And while this growth has been great for the Texas economy overall,
        <span className="text-after o-0">
          {' '}
          that economic growth has largely been confined to the state&apos;s urban areas.
        </span>
      </div>
    ),
    chartMobile: Slide3ChartMobile,
    chartDesktop: Slide3ChartDesktop,
    mountedFunction: () => {
      const line = document.querySelector('[id^=Line]')
      const botLabel = document.querySelector('[id^=Bottom_x5F]')
      const topLabel = document.querySelector('[id^=Top_x5F]')
      const text = document.querySelector('.text')

      text.style.opacity = 0
      text.style.transition = 'all 500ms ease'
      text.style.transform = 'translate(-30px)'

      topLabel.style.opacity = 0
      botLabel.style.opacity = 0
      topLabel.style.transition = 'opacity 1s ease'
      botLabel.style.transition = 'opacity 1s ease'

      line.style.strokeDasharray = 1000
      line.style.strokeDashoffset = 1000

      setTimeout(() => {
        text.style.opacity = 1
        text.style.transform = 'translate(0)'

        botLabel.style.opacity = 1
        topLabel.style.opacity = 1

        line.style.animation = 'dash 3s linear'

        setTimeout(() => (line.style.strokeDasharray = 0), 3000)
      }, SLIDES_TRANSITION_TIME + 200)
    },
  },
  {
    type: 'text-and-image-des-mob',
    content: (
      <div>
        And while this growth has been great for the Texas economy overall,
        <span className="text-after">
          {' '}
          that economic growth has largely been confined to the state&apos;s urban areas.
        </span>
      </div>
    ),
    chartMobile: Slide3BChartMobile,
    chartDesktop: Slide3BChartDesktop,
    mountedFunction: () => {
      const barchart = document.querySelector('[id^=Barchart] #Red')
      const barchartWhite = document.querySelector('[id^=Barchart] #White')
      const botLabel = document.querySelector('[id^=Bottom_x5F]')
      const topLabel = document.querySelector('[id^=Top_x5F]')
      const text = document.querySelector('.text-after')

      text.style.opacity = 0
      text.style.transition = 'opacity 1s ease'

      barchartWhite.style.opacity = 0
      barchartWhite.style.transition = 'opacity 500ms ease'

      barchart.style.opacity = 0
      barchart.style.transform = 'scaleY(0)'
      barchart.style.transformBox = 'fill-box'
      barchart.style.transformOrigin = 'center bottom'

      topLabel.style.opacity = 0
      botLabel.style.opacity = 0
      topLabel.style.transition = 'opacity 500ms ease'
      botLabel.style.transition = 'opacity 500ms ease'

      setTimeout(() => {
        text.style.opacity = 1
        topLabel.style.opacity = 1
        barchartWhite.style.opacity = 1

        setTimeout(() => {
          botLabel.style.opacity = 1

          barchart.style.opacity = 1
          barchart.style.animation = 'draw 1s ease forwards'
        }, 300)

        setTimeout(() => (barchart.style.transform = 'scaleY(1)'), 3000)
      }, SLIDES_TRANSITION_TIME)
    },
  },
  {
    type: 'text-and-image-des-mob',
    content: (
      <div>
        And huge disparities continue to exist. To ensure all Texans have a better future, we have
        to make good policy now.
      </div>
    ),
    chartMobile: Slide4ChartMobile,
    chartDesktop: Slide4ChartDesktop,
    ...topLeftTextAnimation,
    mountedFunction: linesAndLabelsMountedFunction,
  },
  {
    type: 'numbers',
    content: (
      <div>
        In 2036—when we enter the third century of Texas—the people of Texas will be
        <span className="fw8"> much older</span>, <span className="fw8">increasingly diverse</span>,
        and live primarily in <span className="fw8">urban areas</span>.
      </div>
    ),
    numbers: [
      {
        number: '+70%',
        subtitle: (
          <div>
            in <span className="accent">over-65</span> population <br />
            from 2019 to 2036
          </div>
        ),
      },
      {
        number: '+47%',
        subtitle: (
          <div>
            in <span className="accent">non-white</span> population <br />
            from 2019 to 2036
          </div>
        ),
      },
      {
        number: '+33%',
        subtitle: (
          <div>
            in <span className="accent">urban</span> growth <br />
            from 2019 to 2036
          </div>
        ),
      },
    ],
    ...defaultNumberAnimation,
    mountedFunction: numbersMountedFunction,
  },
  {
    type: 'text-and-image-des-mob',
    content: (
      <div>
        <div>
          But we don&apos;t have a lot of time to make good policy. In fact, between now and 2036,
          there are just <span className="fw8">8 opportunities</span> for state policymakers to
          address the needs of over <span className="fw8">10 million</span> new Texans.
          <br />
          <br />
          And there’s a lot of work to do. For example…
        </div>
      </div>
    ),
    ...topLeftTextAnimation,
    chartOnTop: Capitol,
    chartMobile: Slide6ChartMobile,
    chartDesktop: Slide6ChartDesktop,
  },
  {
    type: 'numbers',
    content: (
      <div>
        We&apos;ll need to build a bigger and better PreK-12 education system to provide more than
        <span className="fw8"> 1.5 million</span> additional school-aged Texans with meaningful
        opportunity. <br />
        <br />
        Since Texas currently ranks in the bottom half of U.S. states for reading and math
        proficiency, our work in education could not be more important.
      </div>
    ),
    numbers: [
      {
        number: '+22.5%',
        subtitle: (
          <div>
            in <span className="accent">school-aged Texans</span>
            <br /> by 2036
          </div>
        ),
      },
      {
        number: '44th',
        subtitle: (
          <div>
            in <span className="accent"> 8th Grade Reading </span>
            <br /> among all U.S. States
          </div>
        ),
      },
      {
        number: '28th',
        subtitle: (
          <div>
            in <span className="accent">8th Grade Math</span> <br />
            among all U.S. States
          </div>
        ),
      },
    ],
    ...defaultNumberAnimation,
    mountedFunction: numbersMountedFunction,
  },
  {
    type: 'text-and-image-des-mob',
    content: (
      <div>
        In urban areas, we&apos;ll need to reduce the cost of congestion so Texans waste less time
        and money stuck in traffic. Today, the average urban commuter loses{' '}
        <span className="fw8">54 hours and $981 </span>in traffic each year.
      </div>
    ),
    chartMobile: Slide7ChartMobile,
    chartDesktop: Slide7ChartDesktop,
    ...defaultTextImageAnimation,
    mountedFunction: linesAndLabelsMountedFunction,
  },
  // 8
  {
    type: 'text-and-image',
    content: (
      <div>
        We&apos;ll also need to better connect rural Texas to the digital economy of the future.
        Today, <span className="fw8">31% of rural Texans</span> lack broadband internet, limiting
        access to jobs, education, and health care.
      </div>
    ),
    chart: Slide8Chart,
    ...defaultTextImageAnimation,
    mountedFunction: () => mapOpacityMountedFunction(1000),
  },
  {
    type: 'numbers',
    content: (
      <div>
        Speaking of health, we&apos;ll need innovative solutions that yield a greater return on
        investment by producing significantly improved health outcomes for all Texans.
      </div>
    ),
    numbers: [
      {
        number: '$188.6 billion',
        subtitle: (
          <div>
            spent on publicly and privately funded{' '}
            <span className="accent">health care in 2014</span>
          </div>
        ),
      },
    ],
    ...defaultNumberAnimation,
    mountedFunction: numbersMountedFunction,
  },
  // 10A
  {
    type: 'text-center',
    content: (
      <div>
        Despite health care costs growing faster (<span className="fw8">at 6.9% each year</span>)
        than the national average, Texas ranks in the bottom half of the following key health
        measures...
      </div>
    ),
    ...bigTextAnimation,
  },
  // 10B
  {
    type: 'text-and-image-des-mob',
    content: false,
    chartMobile: Slide10ChartMobile,
    chartDesktop: Slide10ChartDesktop,
    ...bigTextAnimation,
    mountedFunction: () => {
      const labels = document.querySelectorAll('[id^=Label_x5F]')
      const numbers = document.querySelectorAll('[id^=Square_x5F]')
      const hide = el => {
        el.style.opacity = 0
        el.style.transition = 'opacity 1s ease'
      }

      labels.forEach(el => hide(el))
      numbers.forEach(el => hide(el))

      setTimeout(() => {
        labels.forEach((label, i) => {
          setTimeout(() => {
            label.style.opacity = 1
            setTimeout(() => {
              numbers[i].style.opacity = 1
            }, 100)
          }, i * 200)
        })
      }, SLIDES_TRANSITION_TIME)
    },
  },
  {
    type: 'text-and-image-des-mob',
    content: (
      <div>
        Meanwhile, health outcomes and access across the state vary widely by geography, race,
        ethnicity, and income.
      </div>
    ),
    chartMobile: SlideDisparitiesChartMobile,
    chartDesktop: SlideDisparitiesChartDesktop,
    ...defaultTextImageAnimation,
    mountedFunction: () => {
      const bars = document.querySelectorAll('[id^=Bars] path')

      bars.forEach(bar => {
        bar.style.transform = 'scaleY(0)'
        bar.style.transformBox = 'fill-box'
        bar.style.transformOrigin = 'center bottom'
      })

      setTimeout(() => {
        bars.forEach((bar, i) =>
          setTimeout(() => {
            bar.style.animation = 'draw 1s ease forwards'
          }, i * 50)
        )
      }, SLIDES_TRANSITION_TIME)
    },
  },
  {
    type: 'numbers',
    content: (
      <div>
        And we&apos;ll definitely need to ensure we have the right strategies for managing our water
        supply because if a major drought occurred today, Texas could only meet{' '}
        <span className="fw8">74% of its water needs</span>.
      </div>
    ),
    numbers: [
      {
        number: (
          <span>
            4.8 million <br /> acre-feet
          </span>
        ),
        subtitle: (
          <div>
            of <span className="accent">water</span> needed in 2020
          </div>
        ),
      },
    ],
    ...defaultNumberAnimation,
    mountedFunction: numbersMountedFunction,
  },

  {
    type: 'text-and-image',
    content: (
      <div>
        By 2040, water need is projected to be <span className="fw8">6.3 million acre feet</span>,
        with the greatest shortages in our urban population centers and in the High Plains where our
        food and fiber are produced.
      </div>
    ),
    chart: Slide13Chart,
    ...defaultTextImageAnimation,
    mountedFunction: () => {
      mapOpacityMountedFunction()
      const triangle = document.querySelector('#Callouts path')
      const circle = document.querySelector('#Callouts circle')
      const texts = document.querySelectorAll('#Callouts text')

      texts.forEach(text => {
        text.style.opacity = 0
        text.style.transition = 'opacity 2s ease'
      })

      circle.style.strokeDasharray = 1000
      circle.style.strokeDashoffset = 1000

      triangle.style.strokeDasharray = 1000
      triangle.style.strokeDashoffset = 1000

      setTimeout(() => {
        triangle.style.animation = 'dash 2s linear'
        circle.style.animation = 'dash 2s linear'

        texts.forEach(text => {
          text.style.opacity = 1
        })

        setTimeout(() => {
          triangle.style.strokeDasharray = 0
          circle.style.strokeDasharray = 0
        }, 2000)
      }, SLIDES_TRANSITION_TIME)
    },
  },
  // 14
  {
    type: 'text-and-image-des-mob',
    content: (
      <div>
        That shortage is <span className="fw8">5.5 times bigger</span> than Lake Travis in Austin
        and over <span className="fw8">14 times bigger</span> than Lake Ray Hubbard in Dallas.
      </div>
    ),
    chartMobile: Slide14Chart,
    chartDesktop: Slide14Chart,
    ...defaultTextImageAnimation,
  },
  {
    type: 'text-center',
    content: (
      <div>
        <div>
          And while all these challenges can feel overwhelming, we believe Texans are up to the
          task.
        </div>
      </div>
    ),
    ...bigTextAnimation,
  },
  {
    type: 'text-and-image-des-mob',
    content: (
      <div>
        There will be <span className="fw8">10 million new Texans</span> by our bicentennial.
        <br />
        Are we ready for what&apos;s next?
      </div>
    ),
    chartMobile: Slide15ChartMobile,
    chartDesktop: Slide15ChartDesktop,
    ...defaultTextImageAnimation,
    mountedFunction: () => {
      const line = document.querySelector('[id^=Line]')
      const botLabel = document.querySelector('[id^=Bottom_x5F]')
      const topLabel = document.querySelector('[id^=Top_x5F]')
      topLabel.style.opacity = 0
      botLabel.style.opacity = 0
      topLabel.style.transition = 'opacity 1s ease'
      botLabel.style.transition = 'opacity 1s ease'
      line.style.strokeDasharray = 1000
      line.style.strokeDashoffset = 1000
      setTimeout(() => {
        botLabel.style.opacity = 1
        topLabel.style.opacity = 1
        line.style.animation = 'dash 3s linear'
        setTimeout(() => (line.style.strokeDasharray = 0), 3000)
      }, SLIDES_TRANSITION_TIME + 200)
    },
  },
  // End
  {
    type: 'text-center',
    content: (
      <div>
        <div>
          At <span className="fw8">Texas 2036</span>, we are engaging Texans in an honest
          conversation about our future, focusing on our big challenges and pursuing non-partisan
          ideas and modern solutions that are grounded in data to break through the gridlock on
          issues that matter most. The future of Texas is in our hands. <br />
          <span className="fw8">Come and make it</span>.
        </div>
        <br />
        <div>
          <Link to={`${process.env.SUBDIRECTORY}/explorer`}>
            <Button big>Explore data</Button>
          </Link>
        </div>
      </div>
    ),
    ...bigTextAnimation,
  },
]

@inject('state')
@observer
export class Slides extends React.Component {
  render() {
    const index = parseInt(this.props.match.params.id) - 1
    const { ui } = this.props.state
    const slide = slides[index]

    if (Number.isNaN(index)) {
      return <Redirect to={`${process.env.SUBDIRECTORY}/`} />
    }

    return (
      <Swiper className="fixed h-100 w-100 flex flex-column" index={index}>
        <Slide
          key={index}
          className={`flex-auto pv3 ${ui.isMobile ? 'ph3' : 'ph4'}`}
          slide={slide}
        />
        <SlidesMenu
          className={`flex-none pv3 ${ui.isMobile ? 'ph3' : 'ph4'}`}
          index={index}
          slidesNumber={slides.length}
        />
        <SlidesBar className="flex-none" index={index} slidesNumber={slides.length} />
      </Swiper>
    )
  }
}
