import numeral from 'numeral'
import { round, startCase, toLower, upperFirst } from 'lodash'
import { mapRange } from 'canvas-sketch-util/math'

export function formatNumber(n) {
  return numeral(n).format('0,0.[0]')
}

export function formatNumberWithUnit(n) {
  return numeral(n)
    .format('0a')
    .toUpperCase()
}

export function formatRatio(n) {
  return `${n < 1 ? '' : '+'}${round(mapRange(n, 0, 2, -100, 100), 1)}%`
}

export function formatName(name) {
  return upperFirst(toLower(startCase(name)))
}
