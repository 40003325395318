import React from 'react'
import { inject, observer } from 'mobx-react'
import { DESCRIPTIONS } from '../constants'

@inject('state')
@observer
export class Description extends React.Component {
  render() {
    const { className = '' } = this.props
    const { filters } = this.props.state

    return (
      <div className={`${className} f4 lh-copy`}>
        {DESCRIPTIONS[filters.activeChapter]}{' '}
        <a
          href={`${process.env.SUBDIRECTORY}/credits`}
          className="link white fw6 f6 letter-spacing-small transition--fast o-40 hover-o-60 active-o-100"
        >
          <br />
          Credits + sources
        </a>
      </div>
    )
  }
}
