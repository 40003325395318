import React from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { ReactComponent as LogoTexas } from './../assets/texas-logo.svg'
import image1 from './../assets/cover/desktop/1.jpg'
import image2 from './../assets/cover/desktop/2.jpg'
import image3 from './../assets/cover/desktop/3.jpg'
import image4 from './../assets/cover/desktop/4.jpg'
import image5 from './../assets/cover/desktop/5.jpg'
import image6 from './../assets/cover/desktop/6.jpg'
import image7 from './../assets/cover/desktop/7.jpg'
import image8 from './../assets/cover/desktop/8.jpg'
import image9 from './../assets/cover/desktop/9.jpg'
import image10 from './../assets/cover/desktop/10.jpg'
import image11 from './../assets/cover/desktop/11.jpg'
import image12 from './../assets/cover/desktop/12.jpg'
import { Button } from '../ui/Button'
import texasSmall from './../assets/texas-small.png'

const IMAGES = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
]

const IMAGES_INTERVAL = 5000
const TRANSITION_TIME = 500

const transition = `opacity ${TRANSITION_TIME}ms ease, transform ${IMAGES_INTERVAL}ms linear`

@inject('state')
@observer
export default class Cover extends React.Component {
  firstImageRef = React.createRef()
  secondImageRef = React.createRef()

  currentIndex = 1

  get firstImage() {
    return this.firstImageRef.current
  }

  get secondImage() {
    return this.secondImageRef.current
  }

  componentDidMount() {
    this.interval = setInterval(this.changeImages, IMAGES_INTERVAL)

    // this triggers the zooming
    setTimeout(() => {
      this.secondImage.style.transform = `scale(1.06)`
    }, 0)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  changeImages = () => {
    const nextIndex = (this.currentIndex + 1) % IMAGES.length
    const isEven = this.currentIndex % 2 === 0
    // currentImage is the one currently on top
    const currentImage = !isEven ? this.secondImage : this.firstImage
    // nextImage is the one that sits on bottom but is coming up
    const nextImage = isEven ? this.secondImage : this.firstImage

    // this triggers the transition
    currentImage.style.opacity = 0

    // this triggers the zooming
    nextImage.style.transform = `scale(1.05)`

    // at the end of the transition
    setTimeout(() => {
      // put the image back
      currentImage.style.zIndex = 0
      nextImage.style.zIndex = 1

      setTimeout(() => {
        // reset the opacity
        currentImage.style.opacity = null

        // reset the scale
        currentImage.style.transition = null
        currentImage.style.transform = null

        // put the transitino back
        setTimeout(() => {
          currentImage.style.transition = transition
        }, 100)

        // load next image
        currentImage.src = IMAGES[nextIndex]

        this.currentIndex = nextIndex
      }, 100)
    }, TRANSITION_TIME)
  }

  render() {
    const { ui } = this.props.state

    return (
      <div className={`${ui.isMobile ? '' : 'fixed w-100 h-100 flex'}`}>
        <div className={`relative overflow-hidden ${ui.isMobile ? 'vh-60' : 'flex-50'}`}>
          <img
            ref={this.secondImageRef}
            src={IMAGES[0]}
            className={`absolute top-0 left-0 w-100 h-100 object-fit-cover z-1`}
            style={{ transition }}
          />
          <img
            ref={this.firstImageRef}
            src={IMAGES[1]}
            className={`absolute top-0 left-0 w-100 h-100 object-fit-cover`}
            style={{ transition }}
          />
        </div>

        <div className={`flex-50 flex flex-column pv4 ${ui.isMobile ? 'ph3' : 'ph4'}`}>
          <a href="https://texas2036.org/">
            <LogoTexas className="flex-none self-start db white" height={'3.5vh'} />
          </a>
          <div className={`flex flex-auto flex-column justify-center ${ui.isMobile ? 'mt4' : ''}`}>
            <div className="b ttu" style={{ fontSize: '11vh', letterSpacing: 2, lineHeight: 1.03 }}>
              <div>Come and</div>
              <div>make it</div>
            </div>
            <div className="semi-b lh-title mt4" style={{ fontSize: '5vh', letterSpacing: 0.6 }}>
              Texas is changing…
              <br />
              Are we ready for what&apos;s next?
            </div>
          </div>
          <div
            className={`flex-auto flex flex-column justify-between items-center ${
              ui.isMobile ? 'mt4' : ''
            }`}
          >
            <Link to={`${process.env.SUBDIRECTORY}/intro/1`}>
              <Button big>Start</Button>
            </Link>

            <div className="self-stretch flex justify-between items-center mt4">
              <Link
                to={`${process.env.SUBDIRECTORY}/explorer`}
                className={`
                link white outline-0 ttu fw6 letter-spacing-small
                user-select-none nowrap
                flex items-center
                ${ui.isMobile ? 'f6' : ''}
              `}
              >
                {!ui.isMobile && <img src={texasSmall} className="mr3" width="48" />}
                Explore data
              </Link>
              <a
                href="https://texas2036.org/"
                className="link white fw6 letter-spacing-small transition--fast o-40 hover-o-60 active-o-100"
              >
                texas2036.org
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
